import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Button, Card, CardBody, Col, Input, Row } from 'reactstrap';

import Select from 'react-select';
import { useFarmsStore } from 'zustand/farms/farms-store';

const FilterOnDashboard = ({ onFilter }) => {
  const [date, setDate] = useState({});
  const { allFarms: farmsWhenStore, getAllFarms } = useFarmsStore();
  const [selectedOption, setSelectedOption] = useState(farmsWhenStore);

  useEffect(() => {
    getAllFarms();
  }, []);

  const options = useMemo(() => {
    return farmsWhenStore?.map((farm) => ({ value: farm.id, label: farm.name })) || [];
  }, [farmsWhenStore]);

  return (
    <Row>
      <Card style={{ margin: 0, padding: 0 }}>
        <CardBody style={{ margin: 0, paddingRight: '7px', paddingLeft: '7px' }}>
          <Row>
            <Col md={3}>
              <strong>De</strong>
              <Input
                onChange={(event) => setDate({ ...date, initial_date: event.target.value })}
                type="date"
                name="initial_date"
                id="initial_date"
                placeholder="De"
                value={date.initial_date}
              />
            </Col>
            <Col md={3}>
              <strong>Ate</strong>
              <Input
                onChange={(event) => setDate({ ...date, final_date: event.target.value })}
                type="date"
                name="final_date"
                id="final_date"
                placeholder="Ate"
                value={date.final_date}
              />
            </Col>
            <Col>
              <strong>Select Farms</strong>
              <Select
                isMulti
                defaultValue={options}
                onChange={setSelectedOption}
                options={options}
              />
            </Col>
          </Row>
          <Row>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                gap: '5px',
                top: '18px'
              }}>
              <Button
                color="primary"
                onClick={() => {
                  onFilter({
                    initial_date: date.initial_date,
                    final_date: date.final_date,
                    farm_ids: selectedOption.map((option) => option.id ?? option.value)
                  });
                }}>
                Filtrar
              </Button>
              <Button color="danger">Limpar</Button>
            </div>
          </Row>
        </CardBody>
      </Card>
    </Row>
  );
};

export default FilterOnDashboard;

import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Badge,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalHeader,
    ModalBody,
    UncontrolledDropdown,
    Input,
    FormFeedback,
    Label,
    Form,
} from "reactstrap";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator";

import Message from "components/Message";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";
import {
    getKpiCards as onGetKpiCards,
    addNewKpiCard as onAddNewKpiCard,
    updateKpiCard as onUpdateKpiCard,
    deleteKpiCard as onDeleteKpiCard,
    getMovementTypes as onGetMovementTypes,
} from "store/actions";

import { showMessage, hideMessage } from "store/message/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

const KpiCards = props => {

    const dispatch = useDispatch();

    const { kpiCards } = useSelector(state => ({
        kpiCards: state.KpiCard.kpiCards,
    }));

    const { error } = useSelector((state) => ({ error: state.KpiCard.error }));
    const [messageAlert, setMessageAlert] = useState("");
    const [typeMessage, setTypeMessage] = useState("");


    const [modal, setModal] = useState(false);
    const [kpiCardList, setKpiCardList] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [kpiCard, setKpiCard] = useState(null);

    const { movementTypes } = useSelector((state) => ({ movementTypes: state.MovementType.movementTypes }));


    useEffect(() => {
        if (error != null && Object.keys(error).length !== 0) {
            showingMessage(error.message, 'warning');
        }
    }, [error]);

    useEffect(() => {
        dispatch(onGetKpiCards());
    }, [dispatch]);

    useEffect(() => {
        dispatch(onGetMovementTypes());
    }, []);

    //   useEffect(() => {
    //     if (!isEmpty(kpiCards)) {
    //       setKpiCardList(kpiCards);
    //     }
    //   }, [kpiCards]);

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: (kpiCard && kpiCard.name) || '',
            movement_type_id: (kpiCard && kpiCard.movement_type_id) || ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Your Name"),
            movement_type_id: Yup.string().required("Please Enter Your Movement Type")
        }),
        onSubmit: (values) => {
            if (isEdit) {
                const updateKpiCard = {
                    id: kpiCard.id,
                    name: values.name,
                    movement_type_id: values.movement_type_id
                };
                // update kpiCard
                dispatch(onUpdateKpiCard(updateKpiCard));
                validation.resetForm();

                handleAsyncActions('Update');
            } else {
                const newKpiCard = {
                    id: Math.floor(Math.random() * (30 - 20)) + 20,
                    name: values["name"],
                    movement_type_id: values["movement_type_id"]
                };
                // save new kpiCard
                dispatch(onAddNewKpiCard(newKpiCard));
                validation.resetForm();

                handleAsyncActions('Added');
            }
            toggle();
        },
    });

    //pagination customization
    const pageOptions = {
        sizePerPage: 10,
        totalSize: kpiCards.length, // replace later with size(orders),
        custom: true,
    };

    const KpiCardColumns = [
        {
            text: "id",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => <>{row.id}</>,
        },
        {
            dataField: "name",
            text: "name",
            sort: true,
        },
        {
            dataField: "menu",
            isDummyField: true,
            text: "Action",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, kpiCard) => (
                <UncontrolledDropdown direction="left">
                    <DropdownToggle href="#" className="card-drop" tag="i">
                        <i className="mdi mdi-dots-horizontal font-size-18" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem
                            href="#"
                            onClick={() => handleKpiCardClick(kpiCard)}
                        >
                            <i className="fas fa-pencil-alt text-success me-1" />
                            Edit
                        </DropdownItem>
                        <DropdownItem
                            href="#"
                            onClick={() => onClickDelete(kpiCard)}
                        >
                            <i className="fas fa-trash-alt text-danger me-1" />
                            Delete
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            ),
        },
    ];

    const toggle = () => {
        if (modal) {
            setModal(false);
            setKpiCard(null);
        } else {
            setModal(true);
        }
    };


    const handleKpiCardClick = arg => {
        const kpiCard = arg;

        setKpiCard({
            id: kpiCard.id,
            name: kpiCard.name,
            movement_type_id: kpiCard.movement_type_id,
        });

        setIsEdit(true);
        toggle();
    };

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    //delete kpiCard
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (kpiCard) => {
        setKpiCard(kpiCard);
        setDeleteModal(true);
    };

    const handleDeleteKpiCard = () => {
        if (kpiCard.id) {
            dispatch(onDeleteKpiCard(kpiCard));
            onPaginationPageChange(1);
            setDeleteModal(false);

            handleAsyncActions('Deleted');
        }
    };

    const showingMessage = (title, type) => {
        setMessageAlert(title);
        setTypeMessage(type);

        dispatch(showMessage());
        setTimeout(() => { dispatch(hideMessage()) }, 2000)
    }


    const handleAsyncActions = (action) => {
        if (error == null || Object.keys(error).length === 0) {
            showingMessage(`KpiCard ${action} Successfully`, 'success');
        }
    };


    const { SearchBar } = Search;


    // eslint-disable-next-line no-unused-vars
    const handleTableChange = (type, { page, searchText }) => {
        setKpiCardList(
            kpiCards.filter(kpiCard =>
                Object.keys(kpiCard).some(key =>
                    kpiCard[key].toLowerCase().includes(searchText.toLowerCase())
                )
            )
        );
    };

    const handleKpiCardClicks = () => {
        setKpiCardList("");
        setIsEdit(false);
        toggle();
    };

    const defaultSorted = [
        {
            dataField: "id",
            order: "desc",
        },
    ];

    /** set Date formate */
    const handleValidDate = date => {
        const date1 = moment(new Date(date)).format("DD MMM Y");
        return date1;
    };

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteKpiCard}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Message title={messageAlert} type={typeMessage} />

                <MetaTags>
                    <title>KpiCard | SistemasW Fazenda</title>
                </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Ecommerce" breadcrumbItem="KpiCards" />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        keyField="id"
                                        columns={KpiCardColumns}
                                        data={kpiCards}
                                    >
                                        {({ paginationProps, paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField="id"
                                                data={kpiCards || []}
                                                columns={KpiCardColumns}
                                                bootstrap4
                                                search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="mb-2">
                                                            <Col sm="4">
                                                                <div className="search-box ms-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <SearchBar {...toolkitProps.searchProps} />
                                                                        <i className="bx bx-search-alt search-icon" />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col sm="8">
                                                                <div className="text-sm-end">
                                                                    <Button
                                                                        type="button"
                                                                        color="success"
                                                                        className="btn-rounded  mb-2 me-2"
                                                                        onClick={handleKpiCardClicks}
                                                                    >
                                                                        <i className="mdi mdi-plus me-1" />
                                                                        New KpiCard
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        responsive
                                                                        bordered={false}
                                                                        striped={false}
                                                                        defaultSorted={defaultSorted}
                                                                        classes={"table align-middle table-nowrap"}
                                                                        keyField="id"
                                                                        {...toolkitProps.baseProps}
                                                                        onTableChange={handleTableChange}
                                                                        {...paginationTableProps}
                                                                        ref={node}
                                                                    />
                                                                </div>
                                                                <Modal isOpen={modal} toggle={toggle}>
                                                                    <ModalHeader toggle={toggle} tag="h4">
                                                                        {!!isEdit
                                                                            ? "Edit Fazenda"
                                                                            : "Add Fazenda"}
                                                                    </ModalHeader>
                                                                    <ModalBody>
                                                                        <Form
                                                                            onSubmit={(e) => {
                                                                                e.preventDefault();
                                                                                validation.handleSubmit();
                                                                                return false;
                                                                            }}
                                                                        >
                                                                            <Row>
                                                                                <Col className="col-12">
                                                                                    <div className="mb-3">
                                                                                        <Label className="form-label">Nome Fazenda</Label>
                                                                                        <Input
                                                                                            name="name"
                                                                                            type="text"
                                                                                            onChange={validation.handleChange}
                                                                                            onBlur={validation.handleBlur}
                                                                                            value={validation.values.name || ""}
                                                                                            invalid={
                                                                                                validation.touched.name && validation.errors.name ? true : false
                                                                                            }
                                                                                        />
                                                                                        {validation.touched.name && validation.errors.name ? (
                                                                                            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                                                        ) : null}
                                                                                    </div>
                                                                                </Col>

                                                                                <Col className="col-12">
                                                                                    <div className="mb-3">
                                                                                        <div className="mb-3">
                                                                                            <Label className="form-label">Tipo Movimento</Label>
                                                                                            <Input
                                                                                                name="movement_type_id"
                                                                                                type="select"
                                                                                                className="form-select"
                                                                                                onChange={validation.handleChange}
                                                                                                onBlur={validation.handleBlur}
                                                                                                value={validation.values.movement_type_id || ''}
                                                                                                invalid={
                                                                                                    (validation.touched.movement_type_id && validation.errors.movement_type_id) ? true : false
                                                                                                }>
                                                                                                <option value="">Selecione um tipo</option>
                                                                                                {movementTypes.map((movementType) => (
                                                                                                    <option key={movementType.id} value={movementType.id}>
                                                                                                        {movementType.name}
                                                                                                    </option>
                                                                                                ))}
                                                                                            </Input>
                                                                                            {(validation.touched.movement_type_id && validation.errors.movement_type_id) ? (
                                                                                                <FormFeedback type="invalid">{validation.errors.movement_type_id}</FormFeedback>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>

                                                                            </Row>
                                                                            <Row>
                                                                                <Col>
                                                                                    <div className="text-end">
                                                                                        <button
                                                                                            type="submit"
                                                                                            className="btn btn-success save-kpiCard"
                                                                                        >
                                                                                            {props.t("Save")}
                                                                                        </button>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Form>
                                                                    </ModalBody>
                                                                </Modal>
                                                            </Col>
                                                        </Row>
                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                                                <PaginationListStandalone
                                                                    {...paginationProps}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

KpiCards.propTypes = {
    kpiCards: PropTypes.array,
    onGetKpiCards: PropTypes.func,
    onAddNewKpiCard: PropTypes.func,
    onDeleteKpiCard: PropTypes.func,
    onUpdateKpiCard: PropTypes.func,
    onGetMovementTypes: PropTypes.func,
};

export default KpiCards;

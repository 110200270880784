export const formatDate = (date: Date) => {
    const month = `${date.getMonth() + 1}`;
    const day = `${date.getDate()}`;
    const year = date.getFullYear();
    return [year, month, day].join('-');
}

// export const formatDateBr = (data: Date) {
//     const day = String(data.getDate()).padStart(2, '0');
//     const month = String(data.getMonth() + 1).padStart(2, '0');
//     const year = data.getFullYear();
//     return (`${day}/${month}/${year}`)
// }

// export const formatDateDb = (data: Date) {
//     const day = String(data.getDate()).padStart(2, '0');
//     const month = String(data.getMonth() + 1).padStart(2, '0');
//     const year = data.getFullYear();
//     return (`${year}-${month}-${day}`)
// }


import { STATUS_TENANT, USE_TENANT_STORAGE } from 'constants/tenant';
import create from 'zustand';
import { persist } from 'zustand/middleware';

export const useTenantStore = create(
  persist(
    (set, get) => ({
      tenant: {
        url: process.env.REACT_APP_SYSTEM_URL ?? undefined,
        status: STATUS_TENANT.loading
      },
      setTenant: (tenant) => set({ tenant: { ...get().tenant, ...tenant } })
    }),
    {
      name: USE_TENANT_STORAGE,
      getStorage: () => sessionStorage
    }
  )
);

import React from 'react';

export const currencyMask = (e) => {
  let value = null;
  if (isObject(e)) {
    value = e.target.value;
  } else {
    value = e.toString();
  }
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d)(\d{2})$/, '$1,$2');
  value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');
  if (isObject(e)) {
    e.target.value = value;
    return e;
  } else {
    return value;
  }
};

const isObject = (obj) => {
  return Object.prototype.toString.call(obj) === '[object Object]';
};

export const isObjectExport = (obj) => {
  return Object.prototype.toString.call(obj) === '[object Object]';
};

export const currencyMaskValue = (e) => {
  console.log('oquetachegandoaqui', value);
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d)(\d{2})$/, '$1,$2');
  value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');
  e.target.value = value;
  return e;
};

export const intMask = (e) => {
  let value = e.target.value;
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d)(\d{2})$/, '$1.$2');
  // value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
  e.target.value = value;
  return e;
};

export const currencyValueMask = (value) => {
  let valueMask = Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  }).format(value);

  return valueMask;
};

export const decimalValueMask = (value) => {
  let valueMask = Intl.NumberFormat('pt-BR', {
    style: 'decimal',
    currency: 'BRL',
    maximumFractionDigits: 2
  }).format(value);

  return valueMask;
};

export const intValueMask = (value) => {
  let valueMask = Intl.NumberFormat('id').format(value);

  return valueMask;
};

export function formatPrice(value, style = 'decimal') {
  let valueMask = Intl.NumberFormat('pt-BR', {
    style,
    currency: 'BRL',
    minimumFractionDigits: 2
  }).format(value);

  return valueMask;
}

export function sendBancoDadosPrice(price) {
  let value = price.toString();
  value = value.replaceAll('.', '');
  value = value.replace(',', '.');
  return value;
}

export function formatDateBr(dataField) {
  let data = new Date(dataField);
  const day = String(data.getDate()).padStart(2, '0');
  const month = String(data.getMonth() + 1).padStart(2, '0');
  const year = data.getFullYear();
  const dataView = `${day}/${month}/${year}`;
  return dataView;
}

export function formatDateDb(dataField) {
  let data = new Date(dataField);
  const day = String(data.getDate()).padStart(2, '0');
  const month = String(data.getMonth() + 1).padStart(2, '0');
  const year = data.getFullYear();
  const dataDb = `${year}-${month}-${day}`;
  return dataDb;
}

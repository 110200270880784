import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Title from '../Title';
import { Input } from 'reactstrap';
import PlanCard from '../Card';
import Footer from '../Footer';
import * as Yup from 'yup';

import useCheckoutStore from 'zustand/checkout';

function WorkspaceName() {
  // Esquema de validação utilizando Yup
  const validationSchema = Yup.object().shape({
    workspaceName: Yup.string()
      .matches(/^[a-z]+$/, 'Somente letras sem caracteres especiais e minúsculos são permitidos')
      .max(50, 'Nome deve ter no máximo 50 caracteres')
      .required('Nome do espaço é obrigatório')
  });

  const { setWorkspace, setStep, workspace } = useCheckoutStore();
  const handleSubmit = (data, { setSubmitting }) => {
    setWorkspace(data);
    setStep(1);
  };

  return (
    <div style={{ marginTop: '2rem', width: '100%' }}>
      <Title
        title="Crie seu espaço"
        subTitle={`Nome de domínio/usuário do seu espaço,
        com este nome criarei um domínio único para sua fazenda.`}
      />
      <Formik
        initialValues={workspace}
        onSubmit={handleSubmit}
        validationSchema={validationSchema} // Adiciona o esquema de validação
      >
        {({ isSubmitting }) => (
          <Form>
            <Field name="workspaceName">
              {({ field }) => (
                <div>
                  <Input
                    {...field}
                    type="text"
                    color="muted"
                    placeholder="Digite um nome amigável para seu espaço"
                    maxLength={50}
                  />
                  <ErrorMessage name="workspaceName" component="div" className="error" />{' '}
                  {/* Exibe mensagem de erro, se houver */}
                  <small style={{ marginTop: '7px', marginLeft: '3px' }}>
                    Um nome amigável sem espaços e caractéres especiais.
                  </small>
                  <div>
                    <small style={{ marginTop: '7px', marginLeft: '3px' }}>
                      {field.value && `https://${field.value}.wsharefazendas.com.br`}
                    </small>
                  </div>
                </div>
              )}
            </Field>
            <div style={{ marginTop: '2rem' }}>
              <PlanCard slug="" />
            </div>
            <Footer textButton="Continuar" buttonPrevius={''} disabled={isSubmitting} />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default WorkspaceName;

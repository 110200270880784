import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

function MediaZoomItem({ title = '', imageUrl = undefined, ...args }) {
  const [style, setStyle] = useState({
    width: '100%',
    height: '100%',
    textAlign: 'center'
  });

  const imageRef = useRef(null);

  useEffect(() => {}, [style]);

  useEffect(() => {
    if (imageRef) {
      setStyle({
        ...style,
        width: `${imageRef.current?.naturalWidth}px`,
        height: `${imageRef.current?.naturalHeight}px`
      });
    }
  }, [imageRef]);

  return (
    <div>
      <Modal {...args} fullscreen>
        <ModalHeader toggle={args.toggleZoom}>{title ?? 'Zoom Image'}</ModalHeader>
        <ModalBody>
          <div style={style}>
            <img ref={imageRef} src={imageUrl} />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default MediaZoomItem;

export const usePerPageList = (total) => [
  {
    text: '5',
    value: 5
  },
  {
    text: '10',
    value: 10
  },
  {
    text: '50',
    value: 50
  },
  {
    text: 'Todos',
    value: total
  }
];

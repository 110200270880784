/* PROFILES */
export const GET_PROFILES = "GET_PROFILES"
export const GET_PROFILES_SUCCESS = "GET_PROFILES_SUCCESS"
export const GET_PROFILES_FAIL = "GET_PROFILES_FAIL"

/**
 * add PROFILE
 */
export const ADD_NEW_PROFILE = "ADD_NEW_PROFILE"
export const ADD_PROFILE_SUCCESS = "ADD_PROFILE_SUCCESS"
export const ADD_PROFILE_FAIL = "ADD_PROFILE_FAIL"

/**
 * Edit PROFILE
 */
export const UPDATE_PROFILE = "UPDATE_PROFILE"
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS"
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL"

/**
 * Delete PROFILE
 */
export const DELETE_PROFILE = "DELETE_PROFILE"
export const DELETE_PROFILE_SUCCESS = "DELETE_PROFILE_SUCCESS"
export const DELETE_PROFILE_FAIL = "DELETE_PROFILE_FAIL"


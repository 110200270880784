import React from "react"
import { Card, CardBody, CardTitle, Progress } from "reactstrap"

const Distribution = (props) => {
    const { stock } = props;

    const num2 = () => {
        const cal = parseInt(stock.PESO1) + parseInt(stock.PESO2);

        return cal;
    }

    const num3 = () => {
        const cal = parseInt(num2()) + parseInt(stock.PESO3)

        return cal;
    }

    const num4 = () => {
        const cal = parseInt(num3()) + parseInt(stock.PESO4)

        return cal;
    }

    const num5 = () => {
        const cal = parseInt(num4()) + parseInt(stock.PESO5)

        return cal;
    }

    const num6 = () => {
        const cal = parseInt(num5()) + parseInt(stock.PESO6)

        return cal;
    }

    const num7 = () => {
        const cal = parseInt(num6()) + parseInt(stock.PESO7)

        return cal;
    }

    const num8 = () => {
        const cal = parseInt(num7()) + parseInt(stock.PESO8)

        return cal;
    }
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4" style={{ textAlign: 'center' }}>Quadro de Bois {stock.name}</CardTitle>

          <div className="table-responsive mt-4">
            <table className="table align-middle table-nowrap table-striped">
                {/* <thead>
                    <tr>
                        <td>Quadro de Bois fazenda</td>
                    </tr>
                </thead> */}
                <thead>
                    <tr>
                        <td/>
                        <td>Pesos Kg</td>
                        <td/>
                        <td>Qtde</td>
                    </tr>
                </thead>
                <thead>
                    <tr>
                        <td>De</td>
                        <td>Até</td>
                        <td>Qtde</td>
                        <td>Acumulado</td>
                    </tr>
                </thead>
              <tbody>
                <tr>
                    <td>0,00</td>
                    <td>180,00</td>
                    <td>{stock.PESO1}</td>
                    <td>{stock.PESO1}</td>
                </tr>
                <tr>
                    <td>180,00</td>
                    <td>240,00</td>
                    <td>{stock.PESO2}</td>
                    <td>{num2()}</td>
                </tr>
                <tr>
                    <td>240,00</td>
                    <td>300,00</td>
                    <td>{stock.PESO3}</td>
                    <td>{num3()}</td>
                </tr>
                <tr>
                    <td>300,00</td>
                    <td>360,00</td>
                    <td>{stock.PESO4}</td>
                    <td>{num4()}</td>
                </tr>
                <tr>
                    <td>360,00</td>
                    <td>420,00</td>
                    <td>{stock.PESO5}</td>
                    <td>{num5()}</td>
                </tr>
                <tr>
                    <td>420,00</td>
                    <td>480,00</td>
                    <td>{stock.PESO6}</td>
                    <td>{num6()}</td>
                </tr>
                <tr>
                    <td>480,00</td>
                    <td>540,00</td>
                    <td>{stock.PESO7}</td>
                    <td>{num7()}</td>
                </tr>
                <tr>
                    <td>540,00</td>
                    <td>Maior</td>
                    <td>{stock.PESO8}</td>
                    <td>{num8()}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default Distribution

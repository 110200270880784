import React, { useState } from 'react';
import MetaTags from 'react-meta-tags';

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';

import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

//Import Breadcrumb
import Breadcrumbs from '../Common/Breadcrumb';

import { addNewFarm as onAddNewFarm } from 'store/farms/actions';

import { addNewBreed as onAddNewBreed } from 'store/breeds/actions';

import { addNewIron as onAddNewIron } from 'store/irons/actions';

const FormWizard = () => {
  const dispatch = useDispatch();
  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [farmName, setFarmName] = useState('');
  const [breedName, setBreedName] = useState('');
  const [ironName, setIronName] = useState('');

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
      if (tab === 4) {
        dispatch(onAddNewFarm({ name: farmName }));
        dispatch(onAddNewBreed({ name: breedName }));
        dispatch(onAddNewIron({ name: ironName }));
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="Form Wizard" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">System Setup</h4>
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({
                            current: activeTab === 1
                          })}>
                          <NavLink
                            className={classnames({
                              current: activeTab === 1
                            })}
                            onClick={() => {
                              setactiveTab(1);
                            }}
                            disabled={!(passedSteps || []).includes(1)}>
                            <span className="number">1.</span> Farm
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTab === 2
                          })}>
                          <NavLink
                            className={classnames({
                              active: activeTab === 2
                            })}
                            onClick={() => {
                              setactiveTab(2);
                            }}
                            disabled={!(passedSteps || []).includes(2)}>
                            <span className="number ms-2">02</span> Breeds
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({
                            current: activeTab === 3
                          })}>
                          <NavLink
                            className={classnames({
                              active: activeTab === 3
                            })}
                            onClick={() => {
                              setactiveTab(3);
                            }}
                            disabled={!(passedSteps || []).includes(3)}>
                            <span className="number">03</span> Irons
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix mt-4">
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId={1}>
                          <Form>
                            <Row>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label for="basicpill-address-input1">Farm Name</Label>
                                  <textarea
                                    id="basicpill-address-input1"
                                    className="form-control"
                                    rows="1"
                                    placeholder="Enter Farm Name"
                                    onChange={(e) => {
                                      setFarmName(e.target.value);
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>
                          <div>
                            <Form>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label for="basicpill-pancard-input5">Breed Name</Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-pancard-input5"
                                      placeholder="Enter Breed's name."
                                      onChange={(e) => {
                                        setBreedName(e.target.value);
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </TabPane>
                        <TabPane tabId={3}>
                          <div>
                            <Form>
                              <Row>
                                <Col lg="12">
                                  <div className="mb-3">
                                    <Label for="basicpill-namecard-input11">Iron name</Label>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="basicpill-namecard-input11"
                                      placeholder="Enter Iron's name"
                                      onChange={(e) => {
                                        setIronName(e.target.value);
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        </TabPane>
                        <TabPane tabId={4}>
                          <div className="row justify-content-center">
                            <Col lg="6">
                              <div className="text-center">
                                <div className="mb-4">
                                  <i className="mdi mdi-check-circle-outline text-success display-4" />
                                </div>
                                <div>
                                  <h5>Confirm Detail</h5>
                                  <p className="text-muted">
                                    If several languages coalesce, the grammar of the resulting
                                  </p>
                                </div>
                              </div>
                            </Col>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        <li
                          className={
                            activeTab === 1 || activeTab === 4 ? 'previous disabled' : 'previous'
                          }>
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTab(activeTab - 1);
                            }}>
                            Previous
                          </Link>
                        </li>
                        <li className={activeTab === 4 ? 'next disabled' : 'next'}>
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTab(activeTab + 1);
                            }}>
                            Next
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FormWizard;

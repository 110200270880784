import {
  GET_FARMS_SUCCESS,
  GET_FARMS_FAIL,
  ADD_FARM_SUCCESS,
  ADD_FARM_FAIL,
  UPDATE_FARM_SUCCESS,
  UPDATE_FARM_FAIL,
  DELETE_FARM_SUCCESS,
  DELETE_FARM_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  farms: [],
  error: {},
};

const Farm = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_FARMS_SUCCESS:
      return {
        ...state,
        farms: action.payload,
      };

    case GET_FARMS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_FARM_SUCCESS:
      return {
        ...state,
        farms: [...state.farms, action.payload],
      };

    case ADD_FARM_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_FARM_SUCCESS:
      return {
        ...state,
        farms: state.farms.map(farm =>
          farm.id.toString() === action.payload.id.toString()
            ? { farm, ...action.payload }
            : farm
        ),
      };

    case UPDATE_FARM_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_FARM_SUCCESS:
      return {
        ...state,
        farms: state.farms.filter(
          farm => farm.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_FARM_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Farm;

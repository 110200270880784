import { call, put, takeEvery } from 'redux-saga/effects';

// Ecommerce Redux States
import { GET_ANALYTIC_GAINS, GET_SYNTHETIC_GAINS, GET_INVENTORY } from './actionTypes';
import {
  getAnalyticGainsFail,
  getAnalyticGainsSuccess,
  getSyntheticGainsFail,
  getSyntheticGainsSuccess,
  getInventoryFail,
  getInventorySuccess
} from './actions';

//Include Both Helper File with needed methods
import { getAnalyticGains, getSyntheticGains, getInventory } from 'helpers/backend_fazenda_helper';

function* onGetAnalyticGains() {
  try {
    const response = yield call(getAnalyticGains);
    yield put(getAnalyticGainsSuccess(response.data));
  } catch (error) {
    yield put(getAnalyticGainsFail(error));
  }
}

function* onGetSyntheticGains() {
  try {
    const response = yield call(getSyntheticGains);
    yield put(getSyntheticGainsSuccess(response.data));
  } catch (error) {
    yield put(getSyntheticGainsFail(error));
  }
}

function* onGetInventory() {
  try {
    const response = yield call(getInventory);
    yield put(getInventorySuccess(response.data));
  } catch (error) {
    yield put(getInventoryFail(error));
  }
}

function* reportGainSaga() {
  yield takeEvery(GET_SYNTHETIC_GAINS, onGetSyntheticGains);
  yield takeEvery(GET_ANALYTIC_GAINS, onGetAnalyticGains);
  yield takeEvery(GET_INVENTORY, onGetInventory);
}

export default reportGainSaga;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import { formatPrice } from 'utils/inputMask';
import MediaLibrary from 'components/MediaLibrary/MediaLibrary';
import {
  ENTRADA,
  MOVIMENTO_OPERACAO_AVALIACAO,
  MOVIMENTO_OPERACAO_SAIDA,
  MOVIMENTO_OPERACAO_TRANSFERENCIA_ENTRADA,
  MOVIMENTO_OPERACAO_TRANSFERENCIA_SAIDA
} from 'constants/movement';
import useStockStore from 'zustand/stocks/stocks-store';
import Logo from '../../assets/images/wshare.png';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { createWorkbook, createWorksheet } from 'lib/exceljs/Services';
import { withTranslation } from 'react-i18next';

const MovementsModal = (props) => {
  const { isOpen, toggle, movement } = props;
  const { getMovementsByCollectId, movementsByCollect, isLoading } = useStockStore();

  const [localMovements, setLocalMovements] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [isCreating, setIsCreating] = useState(false);

  const toggleEditModal = () => setEditModalOpen(!editModalOpen);

  const handleEditClick = (item) => {
    setCurrentItem(item);
    setIsCreating(false);
    toggleEditModal();
  };

  const handleAddClick = () => {
    setCurrentItem({ earring: '', weight: '', iron_name: '', note: '' });
    setIsCreating(true);
    toggleEditModal();
  };

  const handleDeleteClick = (itemId) => {
    setLocalMovements((prevMovements) => prevMovements.filter((item) => item.id !== itemId));
  };

  const handleSaveEdit = () => {
    if (isCreating) {
      setLocalMovements((prevMovements) => [
        ...prevMovements,
        { ...currentItem, id: Date.now() } // Assigning a temporary ID for new item
      ]);
    } else {
      setLocalMovements((prevMovements) =>
        prevMovements.map((item) => (item.id === currentItem.id ? currentItem : item))
      );
    }
    toggleEditModal();
  };

  const getAllMovements = useCallback(async () => {
    if (isOpen) {
      await getMovementsByCollectId(movement.id, movement.farm_id);
    }
  }, [movement, isOpen, getMovementsByCollectId]);

  useEffect(() => {
    getAllMovements();
  }, [getAllMovements]);

  useEffect(() => {
    setLocalMovements(movementsByCollect);
  }, [movementsByCollect]);

  const collectName = useMemo(() => {
    if (movement) {
      switch (movement.operation) {
        case MOVIMENTO_OPERACAO_AVALIACAO:
        case ENTRADA:
          return 'create_movement';
        case MOVIMENTO_OPERACAO_TRANSFERENCIA_ENTRADA:
          return 'entry_transfer_movement';
        case MOVIMENTO_OPERACAO_SAIDA:
          return 'exit_movement';
        case MOVIMENTO_OPERACAO_TRANSFERENCIA_SAIDA:
          return 'transfer_movement';
        default:
          return '';
      }
    }
  }, [movement]);

  const dataHoraAtual = movement?.movement_date ? new Date(movement.movement_date) : undefined;
  const dia = dataHoraAtual ? String(dataHoraAtual.getDate()).padStart(2, '0') : '00';
  const mes = dataHoraAtual ? String(dataHoraAtual.getMonth() + 1).padStart(2, '0') : '00';
  const ano = dataHoraAtual ? dataHoraAtual.getFullYear() : '00';

  const handleSaveAsPDF = () => {
    const doc = new jsPDF();
    const logoImg = new Image();
    logoImg.onload = function () {
      const width = 40;
      const aspectRatio = logoImg.height / logoImg.width;
      const height = width * aspectRatio;
      doc.addImage(logoImg, 'PNG', 10, 10, width, height);
      const currentDate = `${dia}/${mes}/${ano}`;
      doc.setFontSize(13);
      doc.text(`Data do movimento: ${currentDate}`, 134, 20);
      doc.text('Brincos', 105, 40, null, null, 'center');
      doc.autoTable({
        head: [['Brinco', 'Peso', 'Ferro', 'Nota']],
        body: localMovements.map((item) => [
          item.earring,
          item.weight,
          item.iron_name,
          item.note
        ]),
        startY: 50
      });
      doc.save(`movements-${ano}-${mes}-${dia}-${movement.id}.pdf`);
    };
    logoImg.src = Logo;
  };

  const handleSaveAsXlsx = () => {
    const workbook = createWorkbook();
    const worksheet = createWorksheet(
      workbook,
      `Movimentos ${movement.movement_name} - ${movement.farm_name}`
    );
    worksheet.columns = [
      { header: 'Brinco', key: 'earring', width: 20 },
      { header: 'Peso', key: 'weight', width: 20 },
      { header: 'Ferro', key: 'iron', width: 10 },
      { header: 'Nota', key: 'note', width: 100 },
      { header: 'Action', key: 'action', width: 50 }
    ];
    localMovements.map((item) =>
      worksheet.addRow({
        earring: item.earring,
        weight: item.weight,
        iron: item.iron_name,
        note: item.note
      })
    );
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      a.href = url;
      a.download = `Movimentos#${movement.id} ${ano}-${mes}-${dia} - ${movement.farm_name}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      fullscreen={true}
      toggle={toggle}>
      {(!isLoading && (
        <div className="modal-content container">
          <ModalHeader toggle={toggle}>{props.t('Details Collect')}</ModalHeader>
          <ModalBody>
            <MediaLibrary
              collect={collectName}
              resource="collect"
              resourceId={movement ? movement.id : 0}
              isFileButtonUpload={false}
              isEditableItemName={false}
              isListOfFile={true}
              isSelectFiles={false}
            />
            <div className="table-responsive">
            <div className="text-sm-end">
              <Button
                color="success"
                className="btn-rounded  mb-2 me-2"
                onClick={handleAddClick}
                style={{ marginBottom: '10px' }}>
                {props.t('Add New')}
              </Button>
            </div>
              <Table className="table align-middle table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">{props.t('Earring')}</th>
                    <th scope="col">{props.t('Weight')}</th>
                    <th scope="col">{props.t('Price')}</th>
                    <th scope="col">{props.t('Note')}</th>
                    <th scope="col">{props.t('Action')}</th>
                  </tr>
                </thead>
                <tbody>
                  {localMovements?.length > 0 &&
                    localMovements?.map((itemMovement) => (
                      <tr key={'id_movement' + itemMovement.id}>
                        <td>{itemMovement.earring}</td>
                        <td>{formatPrice(itemMovement.weight)}</td>
                        <td>{itemMovement.iron_name}</td>
                        <td>{itemMovement.note}</td>
                        <td>
                          <Button color="warning" size="sm" onClick={() => handleEditClick(itemMovement)}>Edit</Button>
                          {' '}
                          <Button color="danger" size="sm" onClick={() => handleDeleteClick(itemMovement.id)}>Delete</Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={toggle}>
              {props.t('Close')}
            </Button>
            <Button type="button" color="primary" onClick={handleSaveAsPDF}>
              {props.t('Save as PDF')}
            </Button>
            <Button type="button" color="success" onClick={handleSaveAsXlsx}>
              {props.t('Save as XLSX')}
            </Button>
          </ModalFooter>
        </div>
      )) || (
        <Row>
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100vh',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              opacity: 0.6
            }}>
            <Spinner />
          </div>
        </Row>
      )}
      <Modal isOpen={editModalOpen} toggle={toggleEditModal}>
        <ModalHeader toggle={toggleEditModal}>{isCreating ? props.t('Add Item') : props.t('Edit Item')}</ModalHeader>
        <ModalBody>
          {currentItem && (
            <Form>
              <FormGroup>
                <Label for="earring">{props.t('Earring')}</Label>
                <Input
                  type="text"
                  id="earring"
                  value={currentItem.earring}
                  onChange={(e) => setCurrentItem({ ...currentItem, earring: e.target.value })}
                />
              </FormGroup>
              <FormGroup>
                <Label for="weight">{props.t('Weight')}</Label>
                <Input
                  type="text"
                  id="weight"
                  value={currentItem.weight}
                  onChange={(e) =>
                    setCurrentItem({ ...currentItem, weight: e.target.value.replace(',', '.') })
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="iron_name">{props.t('Price')}</Label>
                <Input
                  type="text"
                  id="iron_name"
                  value={currentItem.iron_name}
                  onChange={(e) => setCurrentItem({ ...currentItem, iron_name: e.target.value })}
                />
              </FormGroup>
              <FormGroup>
                <Label for="note">{props.t('Note')}</Label>
                <Input
                  type="textarea"
                  id="note"
                  value={currentItem.note}
                  onChange={(e) => setCurrentItem({ ...currentItem, note: e.target.value })}
                />
              </FormGroup>
            </Form>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleEditModal}>{props.t('Cancel')}</Button>
          <Button color="primary" onClick={handleSaveEdit}>{props.t('Save')}</Button>
        </ModalFooter>
      </Modal>
    </Modal>
  );
};

MovementsModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  movement: PropTypes.object
};

export default withTranslation()(MovementsModal);

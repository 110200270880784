import { call, put, takeEvery, all, fork } from 'redux-saga/effects';

// Crypto Redux States
import { GET_CHARTS_DATA } from './actionTypes';
import { apiSuccess, apiFail } from './actions';

//Include Both Helper File with needed methods
import {
  getWeeklyData,
  getYearlyData,
  getMonthlyData,
  getDashboard
} from '../../helpers/backend_fazenda_helper';

function* getChartsData({ payload: { de, ate, farms } }) {
  try {
    let response = yield call(getDashboard, de, ate, farms);
    yield put(apiSuccess(GET_CHARTS_DATA, response.data));
  } catch (error) {
    yield put(apiFail(GET_CHARTS_DATA, error));
  }
}

export function* watchGetChartsData() {
  yield takeEvery(GET_CHARTS_DATA, getChartsData);
}

function* dashboardSaga() {
  yield all([fork(watchGetChartsData)]);
}

export default dashboardSaga;

import create from 'zustand';

import axiosApi from 'helpers/api_helper2';

export class DashboardService {
  async getCards(filter) {
    const response = await axiosApi.get(`/dashboard/cards`, { params: filter });

    return response.data.data ?? [];
  }
}

export const dashboardService = new DashboardService();

export const useDashboardStore = create((set, get) => ({
  isLoading: false,

  cards: [],

  getCards: async (filter) => {
    set({ isLoading: true });

    const cards = await dashboardService.getCards(filter);

    set({
      cards,
      isLoading: false
    });
  }
}));

export default useDashboardStore;

/* PLANS DETAILS */
export const GET_PLANS_DETAILS = "GET_PLANS_DETAILS"
export const GET_PLANS_DETAILS_SUCCESS = "GET_PLANS_DETAILS_SUCCESS"
export const GET_PLANS_DETAILS_FAIL = "GET_PLANS_DETAILS_FAIL"

/**
 * add PLAN DETAILS
 */
export const ADD_NEW_PLAN_DETAILS = "ADD_NEW_PLAN_DETAILS"
export const ADD_PLAN_DETAILS_SUCCESS = "ADD_PLAN_DETAILS_SUCCESS"
export const ADD_PLAN_DETAILS_FAIL = "ADD_PLAN_DETAILS_FAIL"

/**
 * Edit PLAN DETAILS
 */
export const UPDATE_PLAN_DETAILS = "UPDATE_PLAN_DETAILS"
export const UPDATE_PLAN_DETAILS_SUCCESS = "UPDATE_PLAN_DETAILS_SUCCESS"
export const UPDATE_PLAN_DETAILS_FAIL = "UPDATE_PLAN_DETAILS_FAIL"

/**
 * Delete PLAN DETAILS
 */
export const DELETE_PLAN_DETAILS = "DELETE_DETAILS_PLAN"
export const DELETE_PLAN_DETAILS_SUCCESS = "DELETE_PLAN_DETAILS_SUCCESS"
export const DELETE_PLAN_DETAILS_FAIL = "DELETE_PLAN_DETAILS_FAIL"


import create from 'zustand';

import axiosApi from 'helpers/api_helper';

export class StockService {
  async get(filter) {
    const response = await axiosApi.get('/stocks', { params: filter });
    return response.data.data ?? {};
  }

  async getMovementsByCollectId(collect_id, farm_id) {
    const response = await axiosApi.get(`/stocks/${collect_id}/movements?farm_id=${farm_id}`);

    return response.data.data ?? [];
  }

  getAdvancedStocks = async (params) => {
    const response = await axiosApi.get('/stocks/advanced', {
      params
    });

    return response.data.data;
  };
}

export const stockService = new StockService();

export const useStockStore = create((set, get) => ({
  isLoading: false,

  movementsByCollect: [],

  advancedStocks: [],

  stock: {
    farms: [],
    movements: [],
    grouped: {}
  },

  getAdvancedStocks: async (params) => {
    set({ isLoading: true });

    const advancedStocks = await stockService.getAdvancedStocks(params);

    return set({
      advancedStocks,
      isLoading: false
    });
  },

  getStocks: async (filter) => {
    set({ isLoading: true });

    const stock = await stockService.get(filter);

    return set({
      stock: { ...stock, farms: stock.farms, movements: stock.movements, grouped: stock.grouped },
      isLoading: false
    });
  },

  getMovementsByCollectId: async (collect_id, farm_id) => {
    set({ isLoading: true });

    const movementsByCollect = await stockService.getMovementsByCollectId(collect_id, farm_id);

    return set({
      movementsByCollect,
      isLoading: false
    });
  }
}));

export default useStockStore;

/* get all PROFILES */
export const GET_ALL_PROFILES = "GET_ALL_PROFILES"
export const GET_ALL_PROFILES_SUCCESS = "GET_ALL_PROFILES_SUCCESS"
export const GET_ALL_PROFILES_FAIL = "GET_ALL_PROFILES_FAIL"

export const GET_PLAN_PROFILES = "GET_PLAN_PROFILES"
export const GET_PLAN_PROFILES_SUCCESS = "GET_PLAN_PROFILES_SUCCESS"
export const GET_PLAN_PROFILES_FAIL = "GET_PLAN_PROFILES_FAIL"

export const GET_PLAN_PROFILES_AVAILABLES = "GET_PLAN_PROFILES_AVAILABLES"
export const GET_PLAN_PROFILES_AVAILABLES_SUCCESS = "GET_PLAN_PROFILES_AVAILABLES_SUCCESS"
export const GET_PLAN_PROFILES_AVAILABLES_FAIL = "GET_PLAN_PROFILES_AVAILABLES_FAIL"

export const ADD_PLAN_PROFILE = "ADD_PLAN_PROFILE"
export const ADD_PLAN_PROFILE_SUCCESS = "ADD_PLAN_PROFILE_SUCCESS"
export const ADD_PLAN_PROFILE_FAIL = "ADD_PLAN_PROFILE_FAIL"

export const DELETE_PLAN_PROFILE = "DELETE_PLAN_PROFILE"
export const DELETE_PLAN_PROFILE_SUCCESS = "DELETE_PLAN_PROFILE_SUCCESS"
export const DELETE_PLAN_PROFILE_FAIL = "DELETE_PLAN_PROFILE_FAIL"


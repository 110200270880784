import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEn from "./locales/en/translation.json";
import translationPt from "./locales/pt/translation.json";

// Configuração das traduções
const resources = {
  en: {
    translation: translationEn,
  },
  pt: {
    translation: translationPt,
  },
};

// Verifica e define o idioma padrão se não estiver no localStorage
const language = localStorage.getItem("I18N_LANGUAGE") || "pt";
localStorage.setItem("I18N_LANGUAGE", language);

i18n
  .use(detector)
  .use(initReactI18next) // Passa i18n para o react-i18next
  .init({
    resources,
    lng: language,
    fallbackLng: "pt", // Usa 'pt' se o idioma detectado não estiver disponível

    keySeparator: false, // Não usamos chaves na forma messages.welcome

    interpolation: {
      escapeValue: false, // O react já protege contra XSS
    },
  });

export default i18n;

import {
    GET_ANALYTIC_GAINS,
    GET_ANALYTIC_GAINS_FAIL,
    GET_ANALYTIC_GAINS_SUCCESS,
    GET_SYNTHETIC_GAINS,
    GET_SYNTHETIC_GAINS_FAIL,
    GET_SYNTHETIC_GAINS_SUCCESS,
    GET_INVENTORY,
    GET_INVENTORY_FAIL,
    GET_INVENTORY_SUCCESS,

} from "./actionTypes"

export const getAnalyticGains = () => ({
    type: GET_ANALYTIC_GAINS,
})

export const getAnalyticGainsSuccess = analyticGains => ({
    type: GET_ANALYTIC_GAINS_SUCCESS,
    payload: analyticGains,
})

export const getAnalyticGainsFail = error => ({
    type: GET_ANALYTIC_GAINS_FAIL,
    payload: error,
})

export const getSyntheticGains = () => ({
    type: GET_SYNTHETIC_GAINS,
})

export const getSyntheticGainsSuccess = syntheticGains => ({
    type: GET_SYNTHETIC_GAINS_SUCCESS,
    payload: syntheticGains,
})

export const getSyntheticGainsFail = error => ({
    type: GET_SYNTHETIC_GAINS_FAIL,
    payload: error,
})

export const getInventory = () => ({
    type: GET_INVENTORY,
})

export const getInventorySuccess = syntheticGains => ({
    type: GET_INVENTORY_SUCCESS,
    payload: syntheticGains,
})

export const getInventoryFail = error => ({
    type: GET_INVENTORY_FAIL,
    payload: error,
})





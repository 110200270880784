export const GET_ANALYTIC_GAINS = 'GET_ANALYTIC_GAINS';
export const GET_ANALYTIC_GAINS_FAIL = 'GET_ANALYTIC_GAINS_FAIL';
export const GET_ANALYTIC_GAINS_SUCCESS = 'GET_ANALYTIC_GAINS_SUCCESS';

export const GET_SYNTHETIC_GAINS = 'GET_SYNTHETIC_GAINS';
export const GET_SYNTHETIC_GAINS_FAIL = 'GET_SYNTHETIC_GAINS_FAIL';
export const GET_SYNTHETIC_GAINS_SUCCESS = 'GET_SYNTHETIC_GAINS_SUCCESS';

export const GET_INVENTORY = 'GET_INVENTORY';
export const GET_INVENTORY_FAIL = 'GET_INVENTORY_FAIL';
export const GET_INVENTORY_SUCCESS = 'GET_INVENTORY_SUCCESS';


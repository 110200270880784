import React from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import './AboutUs.css'; // Importando arquivo CSS para estilos adicionais

// Dados do gráfico
const data = [
  { year: "2014", value: 65 },
  { year: "2015", value: 59 },
  { year: "2016", value: 80 },
  { year: "2017", value: 81 },
  { year: "2018", value: 56 },
  { year: "2019", value: 55 },
  { year: "2020", value: 40 },
  { year: "2021", value: 72 },
  { year: "2022", value: 68 },
  { year: "2023", value: 75 },
];

// Dados dos parceiros
const partners = [
  { name: "Partner 1", logo: "path/to/logo1.png" },
  { name: "Partner 2", logo: "path/to/logo2.png" },
  { name: "Partner 3", logo: "path/to/logo3.png" },
  { name: "Partner 4", logo: "path/to/logo4.png" },
];

const AboutUs = () => {
  return (
    <React.Fragment>
      <section className="section pt-4 bg-light" id="about">
        <Container>
          <Row className="justify-content-center mb-5">
            <Col lg="8" className="text-center">
              <div className="small-title">Sobre nós</div>
              <h2 className="display-7">Somos uma empresa de consultoria em gestão</h2>
              <p className="lead text-muted">Aumentando significativamente a performance do resultado anual de arroba do gado.</p>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg="6" md="12" className="mb-5 mb-lg-0">
              <div className="text-muted">
                <h4 className="mb-4">A atuação em gestão de gado de engorda</h4>
                <p className="mb-4">
                  Estamos atuando desde 2014 em um conglomerado de fazendas. Fomos evoluindo a cada dia para contribuir com os nossos clientes, aumentando significativamente a performance do resultado anual de arroba do gado.
                </p>
                <p className="mb-4">
                  O trabalho foi realizado em conjunto com a equipe da fazenda: vaqueiros, colaboradores da manutenção, colaboradores da área agrícola, gerente da fazenda e os veterinários.
                </p>
              </div>
            </Col>
            <Col lg="6" md="12">
              <div className="chart-container">
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="year" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="value" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col sm="6" lg="4" className="mb-4">
              <Card className="border-0 shadow-sm h-100 card-hover">
                <CardBody className="text-center">
                  <div className="mb-3">
                    <i className="mdi mdi-chart-timeline-variant-shimmer h2 text-success" />
                  </div>
                  <h5 className="font-weight-bold">Atuação direta</h5>
                  <p className="text-muted mb-0">
                    + de 8 anos contínuos de acompanhamento (compra, venda, dentre outros)
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col sm="6" lg="4" className="mb-4">
              <Card className="border-0 shadow-sm h-100 card-hover">
                <CardBody className="text-center">
                  <div className="mb-3">
                    <i className="mdi mdi-timer-sand h2 text-success" />
                  </div>
                  <h5 className="font-weight-bold">Coletas de peso</h5>
                  <p className="text-muted mb-0">
                    + de 40.000 coletas de peso para gerar análise
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col sm="12" lg="4" className="mb-4">
              <Card className="border-0 shadow-sm h-100 card-hover">
                <CardBody className="text-center">
                  <div className="mb-3">
                    <i className="mdi mdi-account-group h2 text-success" />
                  </div>
                  <h5 className="font-weight-bold">Clientes Satisfeitos</h5>
                  <p className="text-muted mb-0">
                    + de 100 clientes satisfeitos com nossos serviços
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default AboutUs;
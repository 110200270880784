import create from 'zustand';
import axiosApi from 'helpers/api_helper';

export class PlanService {
  async get() {
    const response = await axiosApi.get(`/plans`);
    return response.data ?? [];
  }

  async getByUrl(slug) {
    const response = await axiosApi.get(`/plans/${slug}`);
    return response.data ?? {};
  }
}

export const plansService = new PlanService();

export const usePlansStore = create((set) => ({
  isLoading: false,

  plans: [],
  plan: null,

  get: async () => {
    set({ isLoading: true });
    const plans = await plansService.get();
    set({ plans, isLoading: false });
  },

  getByUrl: async (slug) => {
    set({ isLoading: true });
    const plan = await plansService.getByUrl(slug);
    set({ plan, isLoading: false });
  }
}));

export default plansService;

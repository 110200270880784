import { all, fork } from "redux-saga/effects"

//public

import FarmSaga from "./farms/saga"
import BreedSaga from "./breeds/saga"
import IronSaga from "./irons/saga"
import MovementTypeSaga from "./movement-types/saga"
import MovementSaga from "./movements/saga"
import AnimalSaga from "./animals/saga"
import TenantSaga from "./tenants/saga"
import PlanSaga from "./plans/saga"
import ProfileSaga from "./profiles/saga"
import PermissionSaga from "./permissions/saga"
import RoleSaga from "./roles/saga"
import UserSaga from "./users/saga"
import PlanDetailsSaga from "./plan-details/saga"
import ProfilePermissionsSaga from "./profile-permissions/saga"
import PlanProfilesSaga from "./plan-profiles/saga"
import RolePermissionsSaga from "./role-permissions/saga"
import RoleUsersSaga from "./role-user/saga"
import ReportGainSaga from "./reports/gains/saga"


import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import LayoutSaga from "./layout/saga"
import ecommerceSaga from "./e-commerce/saga"
import calendarSaga from "./calendar/saga"
import chatSaga from "./chat/saga"
import cryptoSaga from "./crypto/saga"
import invoiceSaga from "./invoices/saga"
import projectsSaga from "./projects/saga"
import tasksSaga from "./tasks/saga"
import mailsSaga from "./mails/saga"
// import contactsSaga from "./contacts/saga";
import dashboardSaga from "./dashboard/saga";
import dashboardSaasSaga from "./dashboard-saas/saga";
import KpiCardSaga from "./kpi-cards/saga";

import stocksSaga from "./stocks/saga";


export default function* rootSaga() {
  yield all([
    //public

    fork(FarmSaga),
    fork(BreedSaga),
    fork(IronSaga),
    fork(MovementTypeSaga),
    fork(MovementSaga),
    fork(AnimalSaga),
    fork(TenantSaga),
    fork(PlanSaga),
    fork(ProfileSaga),
    fork(PermissionSaga),
    fork(ReportGainSaga),
    fork(RoleSaga),
    fork(UserSaga),
    fork(PlanDetailsSaga),
    fork(ProfilePermissionsSaga),
    fork(RolePermissionsSaga),
    fork(RoleUsersSaga),
    fork(KpiCardSaga),
    fork(PlanProfilesSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(LayoutSaga),
    fork(ecommerceSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(mailsSaga),
    fork(cryptoSaga),
    fork(invoiceSaga),
    fork(projectsSaga),
    fork(tasksSaga),
    // fork(contactsSaga),
    fork(dashboardSaga),
    fork(dashboardSaasSaga),

    fork(stocksSaga)
  ])
}

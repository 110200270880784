import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Row, Button, FormGroup, Input, Col, Card, CardTitle } from 'reactstrap';
import { withTranslation } from 'react-i18next';

const initialValues = {
  collect_id: '',

  animal_earring: '>',
  earringQuantity: 10,

  final_weight: '>',
  final_weightQuantity: 20,

  start_weight: '',
  start_weightQuantity: 30,

  difference_days: '>',
  difference_daysQuantity: 40,

  gains_kg: '>',
  gains_kgQuantity: 50,

  gains_year_arroba: '>',
  gains_year_arrobaQuantity: 60,

  obs: '',

  de: new Date(),
  ate: new Date(),
};

const SearchAnalytic = (props) => {
  const onSubmit = (values) => {
    console.log(values);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Row form>
          <Col md={4}>
            <Card style={{ margin: 0 }}>
                <CardTitle>Collect</CardTitle>
                <FormGroup>
                  <Field as={Input} type="id" name="collect_id" id="collect_id" placeholder="collect" />
                </FormGroup>
              </Card>
          </Col>
          </Row>
          <Row form>
            <Col md={2}>
              <Card style={{ margin: 0 }}>
                <CardTitle>Id</CardTitle>
                <FormGroup>
                  <Field as={Input} type="id" name="animal_id" id="animal_id" placeholder="id" />
                </FormGroup>
              </Card>
            </Col>

            <Col md={4}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t("Earring")}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field as={Input} type="select" name="animal_earring" id="animal_earring" placeholder='Sinal'>
                        {['>', '<', '>=', '<='].map(op => <option key={op} value={op}>{op}</option>)}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field as={Input} type="number" name="earringQuantity" id="earringQuantity" placeholder="Quantity" />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t("Final weight")}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field as={Input} type="select" name="final_weight" id="final_weight" placeholder='Sinal'>
                        {['>', '<', '>=', '<='].map(op => <option key={op} value={op}>{op}</option>)}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field as={Input} type="number" name="final_weightQuantity" id="final_weightQuantity" placeholder="Quantity" />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t("Start weight")}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field as={Input} type="select" name="start_weight" id="start_weight" placeholder='Sinal'>
                        {['>', '<', '>=', '<='].map(op => <option key={op} value={op}>{op}</option>)}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field as={Input} type="number" name="start_weightQuantity" id="start_weightQuantity" placeholder="Quantity" />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t("Difference days")}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field as={Input} type="select" name="difference_days" id="difference_days" placeholder='Sinal'>
                        {['>', '<', '>=', '<='].map(op => <option key={op} value={op}>{op}</option>)}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field as={Input} type="number" name="difference_daysQuantity" id="difference_daysQuantity" placeholder="Quantity" />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t("Gains kg")}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field as={Input} type="select" name="gains_kg" id="gains_kg" placeholder='Sinal'>
                        {['>', '<', '>=', '<='].map(op => <option key={op} value={op}>{op}</option>)}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field as={Input} type="number" name="gains_kgQuantity" id="gains_kgQuantity" placeholder="Quantity" />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t("Gains year arroba")}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field as={Input} type="select" name="gains_year_arroba" id="gains_year_arroba" placeholder='Sinal'>
                        {['>', '<', '>=', '<='].map(op => <option key={op} value={op}>{op}</option>)}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field as={Input} type="number" name="gains_year_arrobaQuantity" id="gains_year_arrobaQuantity" placeholder="Quantity" />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t("Note")}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={12}>
                      <Input type='text' id='obs' name='obs' placeholder='Obs' />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>
            <Col md={3}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t("From")} </CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={12}>
                      <Input type='date' id='de' name='de' placeholder='De' />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>
            <Col md={3}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t("To")} </CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={12}>
                     <Input type='date' id='ate' name='ate' placeholder='Ate' />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>
            {/* Adicione os outros campos de formulário aqui */}
          </Row>
          {/* Adicione os outros grupos de campos de formulário aqui */}
          <Row>
            <div style={{ display: "flex", justifyContent: "center", width: "100%", gap: "10px", marginTop: "3rem", left: "40%" }}>
              <Button color="primary" type="submit">
                {props.t("Search filter")}
              </Button>
              <Button color="danger" type="reset">
                {props.t("Clear")}
              </Button>
            </div>
          </Row>
        </Form>
      )}
    </Formik>
  );
}

export default withTranslation()(SearchAnalytic);


import React, { useEffect, useState, useRef } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { withRouter, Link, useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import * as moment from 'moment';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormFeedback,
  Label,
  Form
} from 'reactstrap';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider
} from 'react-bootstrap-table2-paginator';

import Message from 'components/Message';

//Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb';

import DeleteModal from '../../components/Common/DeleteModal';
import {
  getPlans as onGetPlans,
  addNewPlan as onAddNewPlan,
  updatePlan as onUpdatePlan,
  deletePlan as onDeletePlan
} from 'store/plans/actions';

import { showMessage, hideMessage } from 'store/message/actions';

//redux
import { useSelector, useDispatch } from 'react-redux';

const Plans = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { plans } = useSelector(function (state) {
    return { plans: state.Plan.plans };
  });

  const { error } = useSelector(function (state) {
    return { error: state.Plan.error };
  });
  const [messageAlert, setMessageAlert] = useState('');
  const [typeMessage, setTypeMessage] = useState('');

  const [modal, setModal] = useState(false);
  const [planList, setPlanList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [plan, setPlan] = useState(null);

  useEffect(async () => {
    await dispatch(onGetPlans());
  }, [dispatch]);

  useEffect(() => {
    if (error != null && Object.keys(error).length !== 0) {
      showingMessage(error.message, 'warning');
    }
  }, [error]);

  //   useEffect(() => {
  //     if (!isEmpty(plans)) {
  //       setPlanList(plans);
  //     }
  //   }, [plans]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (plan && plan.name) || '',
      description: (plan && plan.description) || '',
      price: (plan && plan.price) || 0,
      active: plan && plan.active == 1 ? 1 : plan && plan.active == 0 ? 0 : 1
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please Enter Your Name'),
      description: Yup.string().required('Please Enter With Plan Description'),
      price: Yup.string().required('Please Enter With Plan Price'),
      active: Yup.boolean().required('Please Enter Your Active')
    }),
    onSubmit: (values) => {
      if (isEdit) {
        let updatePlan = {
          id: plan.id,
          name: values.name,
          description: values.description,
          price: parseFloat(parseFloat(values.price.replace(',', '.')).toFixed(2)),
          active: values.active
        };
        // update plan
        dispatch(onUpdatePlan(updatePlan));
        validation.resetForm();
        handleAsyncActions('Updated');
      } else {
        let newPlan = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          name: values['name'],
          description: values['description'],
          price: parseFloat(parseFloat(values['price'].replace(',', '.')).toFixed(2)),
          active: values['active']
        };
        // save new plan
        dispatch(onAddNewPlan(newPlan));
        validation.resetForm();
        handleAsyncActions('Added');
      }
      toggle();
    }
  });

  function convertToMoney(value) {
    let floatValue = parseFloat(value);
    let formato = { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' };
    let formatedValue = floatValue.toLocaleString('pt-BR', formato);
    return formatedValue;
  }

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: plans.length, // replace later with size(orders),
    custom: true
  };

  const handleModalProfilesClick = (profile) => {
    history.push(`/plans/${profile.id}/profiles`);
  };

  const PlanColumns = [
    {
      text: 'id',
      dataField: 'id',
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true
    },
    {
      dataField: 'description',
      text: 'Description',
      sort: true
    },
    {
      dataField: 'price',
      text: 'Price',
      sort: true,
      formatter: (cellContent, plan) => convertToMoney(plan.price)
    },
    {
      dataField: 'active',
      text: 'status',
      sort: true,
      formatter: (cellContent, row) => (row.active == 1 ? <>Ativo</> : <>Inativo</>)
    },
    {
      dataField: 'button-actions',
      text: 'Action',
      formatter: (cellContent, plan) => (
        <div className="btn-group float-end gap-2">
          <Button
            type="button"
            color="primary"
            size="sm"
            className=""
            onClick={() => handleModalProfilesClick(plan)}>
            <i className="fas fa-user-shield" />
          </Button>
          <Button
            type="button"
            color="success"
            size="sm"
            className=""
            onClick={() => handleDetailsClick(plan)}>
            <i className="mdi mdi-card-plus" />
          </Button>
          <Button
            type="button"
            color="warning"
            size="sm"
            className=""
            onClick={() => handlePlanClick(plan)}>
            <i className="fa fa-pen" />
          </Button>
          <Button
            type="button"
            color="danger"
            size="sm"
            className=""
            onClick={() => onClickDelete(plan)}>
            <i className="fa fa-trash" />
          </Button>
        </div>
      )
    }
  ];

  const toggle = () => {
    if (modal) {
      setModal(false);
      setPlan(null);
    } else {
      setModal(true);
    }
  };

  const handleDetailsClick = (plan) => {
    history.push(`/plans/${plan.url}/details`, plan);
  };

  const handlePlanClick = (arg) => {
    const plan = arg;

    setPlan({
      id: plan.id,
      name: plan.name,
      description: plan.description,
      price: plan.price,
      active: plan.active
    });

    setIsEdit(true);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete plan
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (plan) => {
    setPlan(plan);
    setDeleteModal(true);
  };

  const handleDeletePlan = () => {
    if (plan.id) {
      dispatch(onDeletePlan(plan));
      onPaginationPageChange(1);
      setDeleteModal(false);

      handleAsyncActions('Deleted');
    }
  };

  const handleAsyncActions = (action) => {
    if (error == null || Object.keys(error).length === 0) {
      showingMessage(`Plan ${action} Successfully`, 'success');
    }
  };

  const showingMessage = (title, type) => {
    setMessageAlert(title);
    setTypeMessage(type);

    dispatch(showMessage());
    setTimeout(() => {
      dispatch(hideMessage());
    }, 2000);
  };

  const { SearchBar } = Search;

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setPlanList(
      plans.filter((plan) =>
        Object.keys(plan).some((key) => plan[key].toLowerCase().includes(searchText.toLowerCase()))
      )
    );
  };

  const handlePlanClicks = () => {
    setPlanList('');
    setIsEdit(false);
    toggle();
  };

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'desc'
    }
  ];

  /** set Date formate */
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format('DD MMM Y');
    return date1;
  };

  const sizePerPageList = (data) => [
    {
      text: '5th',
      value: 5
    },
    {
      text: '10th',
      value: 10
    },
    {
      text: 'All',
      value: data.length
    }
  ];

  return (
    <React.Fragment>
      <Message title={messageAlert} type={typeMessage} />

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletePlan}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Plan | SistemasW Fazenda</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Ecommerce" breadcrumbItem="Plans" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={PlanColumns}
                    data={plans}>
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={plans || []}
                        columns={PlanColumns}
                        bootstrap4
                        search>
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={handlePlanClicks}>
                                    <i className="mdi mdi-plus me-1" />
                                    New Plan
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={'table align-middle table-nowrap'}
                                    keyField="id"
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    ref={node}
                                    sizePerPageList={sizePerPageList}
                                  />
                                </div>
                                <Modal isOpen={modal} toggle={toggle}>
                                  <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit ? 'Edit Plan' : 'Add Plan'}
                                  </ModalHeader>
                                  <ModalBody>
                                    <Form
                                      onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                      }}>
                                      <Row>
                                        <Col className="col-12">
                                          <div className="mb-3">
                                            <Label className="form-label">Name Plan</Label>
                                            <Input
                                              name="name"
                                              type="text"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.name || ''}
                                              invalid={
                                                validation.touched.name && validation.errors.name
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.name && validation.errors.name ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.name}
                                              </FormFeedback>
                                            ) : null}
                                          </div>

                                          <div className="mb-3">
                                            <Label className="form-label">Description</Label>
                                            <Input
                                              name="description"
                                              type="text"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.description || ''}
                                              invalid={
                                                validation.touched.description &&
                                                  validation.errors.description
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.description &&
                                              validation.errors.description ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.description}
                                              </FormFeedback>
                                            ) : null}
                                          </div>

                                          <div className="mb-3">
                                            <Label className="form-label">Price</Label>
                                            <Input
                                              name="price"
                                              type="text"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.price || ''}
                                              invalid={
                                                validation.touched.price && validation.errors.price
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.price && validation.errors.price ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.price}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                        </Col>
                                        <Col className="col-12">
                                          <div className="mb-3">
                                            <div className="mb-3">
                                              <Label className="form-label">Status</Label>
                                              <Input
                                                name="active"
                                                type="select"
                                                className="form-select"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.active || 0}
                                                invalid={
                                                  validation.touched.active &&
                                                    validation.errors.active
                                                    ? true
                                                    : false
                                                }>
                                                <option value="">{props.t('Select')}</option>
                                                <option value={1}>{props.t('Active')}</option>
                                                <option value={0}>{props.t('Inactive')}</option>
                                              </Input>
                                              {validation.touched.active &&
                                                validation.errors.active ? (
                                                <FormFeedback type="invalid">
                                                  {validation.errors.active}
                                                </FormFeedback>
                                              ) : null}
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="text-end">
                                            <button
                                              type="submit"
                                              className="btn btn-success save-plan">
                                              {props.t("Save")}
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Form>
                                  </ModalBody>
                                </Modal>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone {...paginationProps} />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Plans.propTypes = {
  plans: PropTypes.array,
  onGetPlans: PropTypes.func,
  onAddNewPlan: PropTypes.func,
  onDeletePlan: PropTypes.func,
  onUpdatePlan: PropTypes.func
};

export default Plans;

import {

  GET_BREEDS,
  GET_BREEDS_FAIL,
  GET_BREEDS_SUCCESS,
  ADD_NEW_BREED,
  ADD_BREED_SUCCESS,
  ADD_BREED_FAIL,
  UPDATE_BREED,
  UPDATE_BREED_SUCCESS,
  UPDATE_BREED_FAIL,
  DELETE_BREED,
  DELETE_BREED_SUCCESS,
  DELETE_BREED_FAIL,

} from "./actionTypes"

export const getBreeds = () => ({
  type: GET_BREEDS,
})

export const getBreedsSuccess = breeds => ({
  type: GET_BREEDS_SUCCESS,
  payload: breeds,
})

export const getBreedsFail = error => ({
  type: GET_BREEDS_FAIL,
  payload: error,
})

export const addNewBreed = breed => ({
  type: ADD_NEW_BREED,
  payload: breed,
})

export const addBreedSuccess = breed => ({
  type: ADD_BREED_SUCCESS,
  payload: breed,
})

export const addBreedFail = error => ({
  type: ADD_BREED_FAIL,
  payload: error,
})

export const updateBreed = breed => ({
  type: UPDATE_BREED,
  payload: breed,
})

export const updateBreedSuccess = breed => ({
  type: UPDATE_BREED_SUCCESS,
  payload: breed,
})

export const updateBreedFail = error => ({
  type: UPDATE_BREED_FAIL,
  payload: error,
})

export const deleteBreed = breed => ({
  type: DELETE_BREED,
  payload: breed,
})

export const deleteBreedSuccess = breed => ({
  type: DELETE_BREED_SUCCESS,
  payload: breed,
})

export const deleteBreedFail = error => ({
  type: DELETE_BREED_FAIL,
  payload: error,
})


import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';

import { useHistory, useParams } from 'react-router-dom';

import { Modal } from 'reactstrap';

import Message from 'components/Message';

import { showMessage, hideMessage } from 'store/message/actions';

import { useSelector, useDispatch } from 'react-redux';

import {
  getRoleUsers as onGetRoleUsers,
  getRoleUsersAvailable as onGetRoleUsersAvailable,
  addNewRoleUser as onAddNewRoleUser,
  deleteRoleUser as onDeleteRoleUser
} from 'store/actions';

const ModalRoleUsers = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const { roleUsers, rolesAvailable } = useSelector(function (state) {
    return { ...state.RolesUsers };
  });

  const [selectedFilter, setSelectedFilter] = useState([]);
  const [rolesAvailableList, setUsersAvailableList] = useState([]);
  const [roleUsersList, setRoleUsersList] = useState([]);

  const { error } = useSelector(function (state) {
    console.log(state);
    return { error: state.RolesUsers.error };
  });

  const [messageAlert, setMessageAlert] = useState('');
  const [typeMessage, setTypeMessage] = useState('');

  useEffect(() => {
    if (error != null && Object.keys(error).length !== 0 && error.response.data) {
      showingMessage(error.response.data.message, 'warning');
    }
  }, [error]);

  useEffect(() => {
    if (rolesAvailable) {
      setUsersAvailableList(formatData(rolesAvailable));
    }
  }, [rolesAvailable]);

  useEffect(() => {
    if (roleUsers) {
      setRoleUsersList(formatData(roleUsers));

      let availables = [];

      roleUsers?.forEach((role) => availables.push(role?.id));
      setSelectedFilter(availables);
    }
  }, [roleUsers]);

  const formatData = (argData) => {
    const data = argData.map((item) => {
      return { label: item?.name, value: item?.id };
    });
    return data;
  };

  useEffect(() => {
    dispatch(onGetRoleUsers(id));
    dispatch(onGetRoleUsersAvailable(id));
  }, []);

  const handleAsyncActions = (action) => {
    if (error == null || Object.keys(error).length === 0) {
      showingMessage(`Users ${action} Successfully`, 'success');
    }
  };

  const showingMessage = (title, type) => {
    setMessageAlert(title);
    setTypeMessage(type);

    dispatch(showMessage());
    setTimeout(() => {
      dispatch(hideMessage());
    }, 2000);
  };

  const onFilterChange = (selectedFilter) => {
    setSelectedFilter(selectedFilter);
  };

  const onHandleBackToRoles = () => {
    history.goBack();
  };

  const onHandleAddRoleUser = () => {
    console.log(selectedFilter);
    dispatch(onAddNewRoleUser(id, { roles: selectedFilter }));
    handleAsyncActions('Applied');
    onHandleBackToRoles();
  };

  return (
    <>
      <Message title={messageAlert} type={typeMessage} />

      <Modal isOpen={true} backdrop={'static'} toggle={onHandleBackToRoles} id="staticBackdrop">
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Users List
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={onHandleBackToRoles}
            aria-label="Close"></button>
        </div>
        <div className="modal-body">
          <p>Move one or more roles to the right</p>
          {roleUsersList.length == 0 && rolesAvailableList == 0 ? (
            <p>No have roles! Add roles.</p>
          ) : (
            <></>
          )}
          <DualListBox
            filterPlaceholder="Search..."
            options={[...roleUsersList, ...rolesAvailableList]}
            selected={selectedFilter}
            onChange={(item) => onFilterChange(item)}
          />
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-light" onClick={onHandleBackToRoles}>
            Close
          </button>
          <button type="button" className="btn btn-primary" onClick={onHandleAddRoleUser}>
            Apply
          </button>
        </div>
      </Modal>
    </>
  );
};

ModalRoleUsers.propTypes = {
  onGetRoleUsers: PropTypes.func,
  onGetRoleUsersAvailable: PropTypes.func,
  addNewRoleUser: PropTypes.func,
  deleteRoleUser: PropTypes.func
};

export default ModalRoleUsers;

import React, { useEffect, useState, useRef } from 'react';
import MetaTags from 'react-meta-tags';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import SearchAnalytic from './Filters/DroopFilterAnalytical/SearchAnalytic';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  Input,
  FormFeedback,
  Label,
  Form
} from 'reactstrap';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { Table } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import {
  currencyValueMask,
  decimalValueMask,
  formatPrice,
  isObjectExport,
  intMask,
  formatDateBr
} from '../../../utils/inputMask';

import Message from 'components/Message';

// Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb';

import { getAnalyticGains as onGetAnalyticGains } from 'store/reports/gains/actions';

import { showMessage, hideMessage } from 'store/message/actions';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import DroopFAnalytic from './Filters/DroopFilterAnalytical/DroopAnalytic';
import { withTranslation } from 'react-i18next';

const AnalyticGains = (props) => {
  const dispatch = useDispatch();

  const [itens, setItens] = useState([]);

  const { analyticGains } = useSelector((state) => ({
    analyticGains: state.ReportGains.analyticGains
  }));

  const { error } = useSelector((state) => ({ error: state.ReportGains.error }));
  const [messageAlert, setMessageAlert] = useState('');
  const [typeMessage, setTypeMessage] = useState('');

  useEffect(() => {
    if (error != null && Object.keys(error).length !== 0) {
      // showingMessage(error.message, 'warning');
    }
  }, [error]);

  useEffect(() => {
    dispatch(onGetAnalyticGains());
  }, [dispatch]);

  useEffect(() => {
    if (analyticGains.itens && analyticGains.itens.length > 0) {
      setItens(analyticGains.itens);
    }
  }, [analyticGains]);

  // Pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: itens.length,
    custom: true
  };

  const AnalyticGainColumns = [
    {
      text: 'id',
      dataField: 'id',
      hidden: false,
      formatter: (cellContent, row) => <>{row.id}</>
    },
    {
      dataField: 'earring',
      text: `${props.t('Earring')}`
    },
    {
      dataField: 'final_weight',
      text: `${props.t('Final weight')}`,
      formatter: (cellContent, row) => <>{formatPrice(row.final_weight)}</>
    },
    {
      dataField: 'final_date',
      text: `${props.t('Final date')}`,
      formatter: (cellContent, row) => <>{formatDateBr(row.final_date)}</>
    },
    {
      dataField: 'start_weight',
      text: `${props.t('Start weight')}`,
      formatter: (cellContent, row) => <>{formatPrice(row.start_weight)}</>
    },
    {
      dataField: 'start_date',
      text: `${props.t('Start date')}`,
      formatter: (cellContent, row) => <>{formatDateBr(row.start_date)}</>
    },
    {
      dataField: 'difference_days',
      text: `${props.t('Difference days')}`,
      formatter: (cellContent, row) => <>{formatPrice(row.difference_days)}</>
    },
    {
      dataField: 'gains_kg',
      text: `${props.t('Gains kg')}`,
      formatter: (cellContent, row) => <>{formatPrice(row.gains_kg)}</>
    },
    {
      dataField: 'gains_year_arroba',
      text: `${props.t('Gains year arroba')}`,
      formatter: (cellContent, row) => <>{formatPrice(row.gains_year_arroba)}</>
    },
    {
      dataField: 'note',
      text: `${props.t('Note')}`,
      formatter: (cellContent, row) => <>{row.note}</>
    },
    {
      dataField: 'collect_name',
      text: `${props.t('Collect')}`,
      formatter: (cellContent, row) => <>{row.collect_name}</>
    }
  ];

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const showingMessage = (title, type) => {
    setMessageAlert(title);
    setTypeMessage(type);

    dispatch(showMessage());
    setTimeout(() => {
      dispatch(hideMessage());
    }, 2000);
  };

  const { SearchBar } = Search;

  const handleTableChange = (type, { page, searchText }) => {
    setAnalyticGainList(
      itens.filter((analyticGain) =>
        Object.keys(analyticGain).some((key) =>
          analyticGain[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    );
  };

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'desc'
    }
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>AnalyticGain | Farm SystemW</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title={props.t('Report')} breadcrumbItem={props.t('Analytic gains')} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={AnalyticGainColumns}
                    data={itens}>
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={itens || []}
                        columns={AnalyticGainColumns}
                        bootstrap4
                        search>
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <DroopFAnalytic />
                            </Row>
                            <Row>
                              <div className="pagination-size-dropdown">
                                <SizePerPageDropdownStandalone {...paginationProps} />
                              </div>

                              <Col xl="12">
                                <div className="table-rep-plugin">
                                  <div
                                    className="table-responsive mb-0"
                                    data-pattern="priority-columns">
                                    <Table id="itens-1">
                                      <BootstrapTable
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={'table table-striped table-bordered'}
                                        keyField="id"
                                        {...toolkitProps.baseProps}
                                        onTableChange={handleTableChange}
                                        {...paginationTableProps}
                                        ref={node}
                                      />
                                    </Table>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone {...paginationProps} />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

AnalyticGains.propTypes = {
  analyticGains: PropTypes.array,
  onGetAnalyticGains: PropTypes.func
};

export default withTranslation()(AnalyticGains);

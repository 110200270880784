import {
  GET_KPICARDS_SUCCESS,
  GET_KPICARDS_FAIL,
  ADD_KPICARD_SUCCESS,
  ADD_KPICARD_FAIL,
  UPDATE_KPICARD_SUCCESS,
  UPDATE_KPICARD_FAIL,
  DELETE_KPICARD_SUCCESS,
  DELETE_KPICARD_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  kpiCards: [],
  error: {},
};

const KpiCard = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_KPICARDS_SUCCESS:
      return {
        ...state,
        kpiCards: action.payload,
      };

    case GET_KPICARDS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_KPICARD_SUCCESS:
      return {
        ...state,
        kpiCards: [...state.kpiCards, action.payload],
      };

    case ADD_KPICARD_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_KPICARD_SUCCESS:
      return {
        ...state,
        kpiCards: state.kpiCards.map(kpiCard =>
          kpiCard.id.toString() === action.payload.id.toString()
            ? { kpiCard, ...action.payload }
            : kpiCard
        ),
      };

    case UPDATE_KPICARD_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_KPICARD_SUCCESS:
      return {
        ...state,
        kpiCards: state.kpiCards.filter(
          kpiCard => kpiCard.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_KPICARD_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default KpiCard;

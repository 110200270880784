import {

  GET_ROLE_PERMISSIONS,
  GET_ROLE_PERMISSIONS_SUCCESS,
  GET_ROLE_PERMISSIONS_FAIL,

  GET_ROLE_PERMISSIONS_AVAILABLES,
  GET_ROLE_PERMISSIONS_AVAILABLES_SUCCESS,
  GET_ROLE_PERMISSIONS_AVAILABLES_FAIL,

  ADD_ROLE_PERMISSION,
  ADD_ROLE_PERMISSION_SUCCESS,
  ADD_ROLE_PERMISSION_FAIL,
  
  DELETE_ROLE_PERMISSION,
  DELETE_ROLE_PERMISSION_SUCCESS,
  DELETE_ROLE_PERMISSION_FAIL
} from "./actionTypes"


export const getRolePermissions = (roleId) => ({
  type: GET_ROLE_PERMISSIONS,
  roleId: roleId,
})

export const getRolePermissionsSuccess = rolePermission => ({
  type: GET_ROLE_PERMISSIONS_SUCCESS,
  payload: rolePermission,
})

export const getRolePermissionsFail = error => ({
  type: GET_ROLE_PERMISSIONS_FAIL,
  payload: error,
})

export const getRolePermissionsAvailable = (roleId) => ({
  type: GET_ROLE_PERMISSIONS_AVAILABLES,
  roleId: roleId,
})

export const getRolePermissionsAvailablesSuccess = rolePermissionAvailable => ({
  type: GET_ROLE_PERMISSIONS_AVAILABLES_SUCCESS,
  payload: rolePermissionAvailable,
})

export const getRolePermissionsAvailablesFail = error => ({
  type: GET_ROLE_PERMISSIONS_AVAILABLES_FAIL,
  payload: error,
})

export const addNewRolePermission = (roleId, rolePermission) => ({
  type: ADD_ROLE_PERMISSION,
  roleId: roleId,
  payload: rolePermission,
})

export const addNewRolePermissionSuccess = rolePermission => ({
  type: ADD_ROLE_PERMISSION_SUCCESS,
  payload: rolePermission,
})

export const addNewRolePermissionFail = error => ({
  type: ADD_ROLE_PERMISSION_FAIL,
  payload: error,
})

export const deleteRolePermission = (roleId, rolePermission) => ({
  type: DELETE_ROLE_PERMISSION,
  roleId: roleId,
  payload: rolePermission,
})

export const deleteRolePermissionSuccess = rolePermission => ({
  type: DELETE_ROLE_PERMISSION_SUCCESS,
  payload: rolePermission,
})

export const deleteRolePermissionFail = error => ({
  type: DELETE_ROLE_PERMISSION_FAIL,
  payload: error,
})


import React from 'react'

const Title = ({ title, subTitle }) => {
  return (
    <>
      <h3>{title}</h3>
      <div style={{ fontWeight: 100, marginTop: '15px', marginBottom: '1rem' }}>
        {subTitle}
      </div>
    </>
  )
}

export default Title
/* get all USERS */
export const GET_ALL_USERS = "GET_ALL_USERS"
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS"
export const GET_ALL_USERS_FAIL = "GET_ALL_USERS_FAIL"

export const GET_ROLE_USERS = "GET_ROLE_USERS"
export const GET_ROLE_USERS_SUCCESS = "GET_ROLE_USERS_SUCCESS"
export const GET_ROLE_USERS_FAIL = "GET_ROLE_USERS_FAIL"

export const GET_ROLE_USERS_AVAILABLES = "GET_ROLE_USERS_AVAILABLES"
export const GET_ROLE_USERS_AVAILABLES_SUCCESS = "GET_ROLE_USERS_AVAILABLES_SUCCESS"
export const GET_ROLE_USERS_AVAILABLES_FAIL = "GET_ROLE_USERS_AVAILABLES_FAIL"

export const ADD_ROLE_USER = "ADD_ROLE_USER"
export const ADD_ROLE_USER_SUCCESS = "ADD_ROLE_USER_SUCCESS"
export const ADD_ROLE_USER_FAIL = "ADD_ROLE_USER_FAIL"

export const DELETE_ROLE_USER = "DELETE_ROLE_USER"
export const DELETE_ROLE_USER_SUCCESS = "DELETE_ROLE_USER_SUCCESS"
export const DELETE_ROLE_USER_FAIL = "DELETE_ROLE_USER_FAIL"


import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';

import Message from 'components/Message';
import { StockService } from 'zustand/stocks/stocks-store';

const Stocks = (props) => {
  const [messageAlert, setMessageAlert] = useState('');
  const [typeMessage, setTypeMessage] = useState('');
  const [stock, setStock] = useState({});
  const { filters } = props;

  const getTenantsCallback = useCallback(async () => {
    const stockService = new StockService();
    const response = await stockService.get(filters);
    setStock(response);
  }, [filters]);

  useEffect(() => {
    getTenantsCallback();
  }, [getTenantsCallback]);

  const stocks = useMemo(() => {
    const grouped = stock.grouped;

    const stocksByFarm = [];
    let totalEmEstoque = 0;

    stock?.farms?.map((farm) => {
      const farmGrouped = grouped[farm.name];

      stocksByFarm.push({
        stock: farmGrouped.estoque,
        farm_name: farm.name,
        movimentos: farmGrouped.movements
      });

      totalEmEstoque = totalEmEstoque + farmGrouped.estoque;
    });

    return {
      stocksByFarm,
      totalEmEstoque
    };
  }, [stock]);

  return (
    <React.Fragment>
      <Message title={messageAlert} type={typeMessage} />
      <Card>
        <CardBody>
          <CardTitle className="mb-4">{props.t('Stock by Farm')}</CardTitle>
          <div className="text-center">
            <div className="mb-4">
              <i className="bx bx-map-pin text-primary display-4" />
            </div>
            <h3>{stocks.totalEmEstoque}</h3>
          </div>

          <div className="table-responsive mt-4">
            {/* Reports Render */}
            {stocks.stocksByFarm.length > 0 &&
              stocks.stocksByFarm.map((st, key) => (
                <div key={'_col_' + key}>
                  <table className="table align-middle table-nowrap">
                    <tbody>
                      <tr>
                        <td style={{ width: '70%' }}>
                          <p className="mb-0">{st.farm_name}</p>
                        </td>
                        <td style={{ width: '30%' }}>
                          <h5 className="mb-0">{st.stock}</h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ))}
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Stocks;

import {
    GET_STOCKS_SUCCESS,
    GET_STOCKS_FAIL
} from "./actionTypes"

const INIT_STATE = {
    stocks: [],
    error: {},
}

const Stocks = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_STOCKS_SUCCESS:
        return {
          ...state,
          stocks: action.payload,
        }

      case GET_STOCKS_FAIL:
        return {
          ...state,
          error: action.payload,
        }

      default:
        return state
    }
  }

  export default Stocks;

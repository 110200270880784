import { call, put, takeEvery } from "redux-saga/effects";

// Animal Redux States
import {
  GET_ANIMALS,
  UPDATE_ANIMAL,
} from "./actionTypes";
import {
  getAnimalsFail,
  getAnimalsSuccess,
  updateAnimalSuccess,
  updateAnimalFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getAnimals,
  updateAnimal,
} from "helpers/backend_fazenda_helper";

function* onGetAnimals() {
  try {
    const response = yield call(getAnimals);
    yield put(getAnimalsSuccess(response.data));
  } catch (error) {
    yield put(getAnimalsFail(error));
  }
}

function* onUpdateAnimal({ payload: animal }) {
  try {
    const response = yield call(updateAnimal, animal);
    yield put(updateAnimalSuccess(response.data));

  } catch (error) {
    yield put(updateAnimalFail(error));
  }
}

function* animalSaga() {
  yield takeEvery(GET_ANIMALS, onGetAnimals);
  yield takeEvery(UPDATE_ANIMAL, onUpdateAnimal);
}

export default animalSaga;

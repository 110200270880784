import {
  GET_MOVEMENTS,
  GET_MOVEMENTS_FAIL,
  GET_MOVEMENTS_SUCCESS,
  ADD_NEW_MOVEMENT,
  ADD_MOVEMENT_SUCCESS,
  ADD_MOVEMENT_FAIL,
  UPDATE_MOVEMENT,
  UPDATE_MOVEMENT_SUCCESS,
  UPDATE_MOVEMENT_FAIL,
  GET_ANIMALS_BY_FARM,
  GET_ANIMALS_BY_FARM_FAIL,
  GET_ANIMALS_BY_FARM_SUCCESS,
  ADD_NEW_MONITORING,
  ADD_MONITORING_SUCCESS,
  ADD_MONITORING_FAIL,
  IMPORT_MOVEMENT,
  IMPORT_MOVEMENT_SUCCESS,
  IMPORT_MOVEMENT_FAIL,
  DELETE_MOVEMENT,
  DELETE_MOVEMENT_SUCCESS,
  DELETE_MOVEMENT_FAIL
} from './actionTypes';

export const getAnimalsByFarm = (farmId) => ({
  type: GET_ANIMALS_BY_FARM,
  farmId
});

export const getAnimalsByFarmSuccess = (animals) => ({
  type: GET_ANIMALS_BY_FARM_SUCCESS,
  payload: animals
});

export const getAnimalsByFarmFail = (error) => ({
  type: GET_ANIMALS_BY_FARM_FAIL,
  payload: error
});

export const getMovements = () => ({
  type: GET_MOVEMENTS
});

export const getMovementsSuccess = (movements) => ({
  type: GET_MOVEMENTS_SUCCESS,
  payload: movements
});

export const getMovementsFail = (error) => ({
  type: GET_MOVEMENTS_FAIL,
  payload: error
});

export const addNewMovement = (movement) => ({
  type: ADD_NEW_MOVEMENT,
  payload: movement
});

export const addMovementSuccess = (movement) => ({
  type: ADD_MOVEMENT_SUCCESS,
  payload: movement
});

export const addMovementFail = (error) => ({
  type: ADD_MOVEMENT_FAIL,
  payload: error
});

export const updateMovement = (movement) => ({
  type: UPDATE_MOVEMENT,
  payload: movement
});

export const updateMovementSuccess = (movement) => ({
  type: UPDATE_MOVEMENT_SUCCESS,
  payload: movement
});

export const updateMovementFail = (error) => ({
  type: UPDATE_MOVEMENT_FAIL,
  payload: error
});

export const deleteMovement = (movement) => ({
  type: DELETE_MOVEMENT,
  payload: movement
});

export const deleteMovementSuccess = (movement) => ({
  type: DELETE_MOVEMENT_SUCCESS,
  payload: movement
});

export const deleteMovementFail = (error) => ({
  type: DELETE_MOVEMENT_FAIL,
  payload: error
});

export const addNewMonitoring = (monitoring) => ({
  type: ADD_NEW_MONITORING,
  farmId,
  payload: monitoring
});

export const addMonitoringSuccess = (monitoring) => ({
  type: ADD_MONITORING_SUCCESS,
  payload: monitoring
});

export const addMonitoringFail = (error) => ({
  type: ADD_MONITORING_FAIL,
  payload: error
});

export const importMovement = (file) => ({
  type: IMPORT_MOVEMENT,
  payload: file
});

export const importMovementSuccess = (file) => ({
  type: IMPORT_MOVEMENT_SUCCESS,
  payload: file
});

export const importMovementFail = (error) => ({
  type: IMPORT_MOVEMENT_FAIL,
  payload: error
});

import {
  GET_MOVEMENT_TYPES_SUCCESS,
  GET_MOVEMENT_TYPES_FAIL,
  ADD_MOVEMENT_TYPE_SUCCESS,
  ADD_MOVEMENT_TYPE_FAIL,
  UPDATE_MOVEMENT_TYPE_SUCCESS,
  UPDATE_MOVEMENT_TYPE_FAIL,
  DELETE_MOVEMENT_TYPE_SUCCESS,
  DELETE_MOVEMENT_TYPE_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  movementTypes: [],
  error: {},
};

const MovementType = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_MOVEMENT_TYPES_SUCCESS:
      return {
        ...state,
        movementTypes: action.payload,
      };

    case GET_MOVEMENT_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_MOVEMENT_TYPE_SUCCESS:
      return {
        ...state,
        movementTypes: [...state.movementTypes, action.payload],
      };

    case ADD_MOVEMENT_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_MOVEMENT_TYPE_SUCCESS:
      return {
        ...state,
        movementTypes: state.movementTypes.map(movementType =>
          movementType.id.toString() === action.payload.id.toString()
            ? { movementType, ...action.payload }
            : movementType
        ),
      };

    case UPDATE_MOVEMENT_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_MOVEMENT_TYPE_SUCCESS:
      return {
        ...state,
        movementTypes: state.movementTypes.filter(
          movementType => movementType.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_MOVEMENT_TYPE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default MovementType;

import create from 'zustand';
import axiosApi from 'helpers/api_helper';

export class FarmService {
  async get(filter) {
    const response = await axiosApi.get(`/farms`, { params: filter });
    return response.data.data ?? [];
  }

  async getById(id) {
    const response = await axiosApi.get(`/farms/${id}`);
    return response.data.data ?? {};
  }
}

export const farmsService = new FarmService();

export const useFarmsStore = create((set) => ({
  isLoading: false,

  farms: [],
  allFarms: [],
  farm: null,

  getAllFarms: async () => {
    set({ isLoading: true });
    const allFarms = await farmsService.get();
    set({ allFarms, isLoading: false });
  },

  getFarms: async (filter) => {
    set({ isLoading: true });
    const farms = await farmsService.get(filter);
    set({ farms, isLoading: false });
  },

  getFarmsById: async (id) => {
    set({ isLoading: true });
    const farm = await farmsService.getById(id);
    set({ farm, isLoading: false });
  }
}));

export default farmsService;

import React, { useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Form } from 'reactstrap';

import DropFilter from './Filter';
import { withTranslation } from 'react-i18next';

function DropFilterInventory(props, { onFilter }) {
  const [open, setOpen] = useState(false);
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <Form style={{ marginBottom: '10px' }}>
      <Accordion style={{ backgroundColor: '#fff' }} open={open} toggle={toggle}>
        <AccordionItem>
          <AccordionHeader targetId="1">
            <i className="bx bx-search-alt search-icon" />
            {props.t("Search Filter")}
          </AccordionHeader>
          <AccordionBody accordionId="1">
            <DropFilter onFilter={onFilter} />
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </Form>
  );
}

export default withTranslation()(DropFilterInventory);


import {
  GET_ROLE_USERS_SUCCESS,
  GET_ROLE_USERS_FAIL,

  GET_ROLE_USERS_AVAILABLES_SUCCESS,
  GET_ROLE_USERS_AVAILABLES_FAIL,

  ADD_ROLE_USER_SUCCESS,
  ADD_ROLE_USER_FAIL,

  DELETE_ROLE_USER_SUCCESS,
  DELETE_ROLE_USER_FAIL
} from "./actionTypes";

const INIT_STATE = {
  roleUsers: [],
  rolesAvailable: [],
  error: {},
};

const RoleUsers = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_ROLE_USERS_SUCCESS:
      return {
        state,
        roleUsers: action.payload,
      };

    case GET_ROLE_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_ROLE_USERS_AVAILABLES_SUCCESS:
      return {
        state,
        rolesAvailable: action.payload,
      };

    case GET_ROLE_USERS_AVAILABLES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_ROLE_USER_SUCCESS:
      return {
        ...state,
        roleUsers: [...state.roleUsers, action.payload],
      };

    case ADD_ROLE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_ROLE_USER_SUCCESS:
      return {
        ...state,
          roleUsers: state.roleUsers.filter(
          roleUsers => roleUsers.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_ROLE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default RoleUsers;

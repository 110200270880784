import {

  GET_PROFILE_PERMISSIONS,
  GET_PROFILE_PERMISSIONS_SUCCESS,
  GET_PROFILE_PERMISSIONS_FAIL,

  GET_PROFILE_PERMISSIONS_AVAILABLES,
  GET_PROFILE_PERMISSIONS_AVAILABLES_SUCCESS,
  GET_PROFILE_PERMISSIONS_AVAILABLES_FAIL,

  ADD_PROFILE_PERMISSION,
  ADD_PROFILE_PERMISSION_SUCCESS,
  ADD_PROFILE_PERMISSION_FAIL,
  
  DELETE_PROFILE_PERMISSION,
  DELETE_PROFILE_PERMISSION_SUCCESS,
  DELETE_PROFILE_PERMISSION_FAIL
} from "./actionTypes"


export const getProfilePermissions = (profileId) => ({
  type: GET_PROFILE_PERMISSIONS,
  profileId: profileId,
})

export const getProfilePermissionsSuccess = profilePermission => ({
  type: GET_PROFILE_PERMISSIONS_SUCCESS,
  payload: profilePermission,
})

export const getProfilePermissionsFail = error => ({
  type: GET_PROFILE_PERMISSIONS_FAIL,
  payload: error,
})

export const getProfilePermissionsAvailable = (profileId) => ({
  type: GET_PROFILE_PERMISSIONS_AVAILABLES,
  profileId: profileId,
})

export const getProfilePermissionsAvailablesSuccess = profilePermissionAvailable => ({
  type: GET_PROFILE_PERMISSIONS_AVAILABLES_SUCCESS,
  payload: profilePermissionAvailable,
})

export const getProfilePermissionsAvailablesFail = error => ({
  type: GET_PROFILE_PERMISSIONS_AVAILABLES_FAIL,
  payload: error,
})

export const addNewProfilePermission = (profileId, profilePermission) => ({
  type: ADD_PROFILE_PERMISSION,
  profileId: profileId,
  payload: profilePermission,
})

export const addNewProfilePermissionSuccess = profilePermission => ({
  type: ADD_PROFILE_PERMISSION_SUCCESS,
  payload: profilePermission,
})

export const addNewProfilePermissionFail = error => ({
  type: ADD_PROFILE_PERMISSION_FAIL,
  payload: error,
})

export const deleteProfilePermission = (profileId, profilePermission) => ({
  type: DELETE_PROFILE_PERMISSION,
  profileId: profileId,
  payload: profilePermission,
})

export const deleteProfilePermissionSuccess = profilePermission => ({
  type: DELETE_PROFILE_PERMISSION_SUCCESS,
  payload: profilePermission,
})

export const deleteProfilePermissionFail = error => ({
  type: DELETE_PROFILE_PERMISSION_FAIL,
  payload: error,
})


import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
// import Profile from "./auth/profile/reducer"

//E-commerce
import ecommerce from "./e-commerce/reducer"

//Calendar
import calendar from "./calendar/reducer"

//chat
import chat from "./chat/reducer"

//crypto
import crypto from "./crypto/reducer"

//invoices
import invoices from "./invoices/reducer"

//projects
import projects from "./projects/reducer"

//tasks
import tasks from "./tasks/reducer"

//contacts
import contacts from "./contacts/reducer"

//mails
import mails from "./mails/reducer";

//Dashboard
import Dashboard from "./dashboard/reducer";

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer";

// Front
import Farm from "./farms/reducer"
import Breed from "./breeds/reducer"
import Iron from "./irons/reducer"
import MovementType from "./movement-types/reducer"
import Movement from "./movements/reducer"
import Animal from "./animals/reducer"
import Tenant from "./tenants/reducer"
import Plan from "./plans/reducer"
import Profile from "./profiles/reducer"
import Permission from "./permissions/reducer"
import Role from "./roles/reducer"
import User from "./users/reducer"
import PlanDetails from "./plan-details/reducer"
import Message from "./message/reducer"
import ProfilePermissions from "./profile-permissions/reducer"
import PlanProfiles from "./plan-profiles/reducer"
import RolesPermissions from "./role-permissions/reducer"
import RolesUsers from "./role-user/reducer"
import KpiCard from "./kpi-cards/reducer"
import ReportGains from "./reports/gains/reducer"

// Stocks
import Stocks from "./stocks/reducer";


const rootReducer = combineReducers({
  // public
  Iron,
  Farm,
  Breed,
  MovementType,
  Movement,
  Animal,
  Tenant,
  Plan,
  Profile,
  Permission,
  Role,
  User,
  ReportGains,
  PlanDetails,
  Message,
  ProfilePermissions,
  RolesPermissions,
  RolesUsers,
  KpiCard,
  PlanProfiles,
  Layout,
  Login,
  Account,
  ForgetPassword,
  ecommerce,
  calendar,
  chat,
  mails,
  crypto,
  invoices,
  projects,
  tasks,
//   contacts,
  Dashboard,
  DashboardSaas,

  Stocks
})

export default rootReducer

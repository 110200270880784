/* MOVEMENTS */
export const GET_MOVEMENTS = "GET_MOVEMENTS"
export const GET_MOVEMENTS_SUCCESS = "GET_MOVEMENTS_SUCCESS"
export const GET_MOVEMENTS_FAIL = "GET_MOVEMENTS_FAIL"

/**
 * add MOVEMENT
 */
export const ADD_NEW_MOVEMENT = "ADD_NEW_MOVEMENT"
export const ADD_MOVEMENT_SUCCESS = "ADD_MOVEMENT_SUCCESS"
export const ADD_MOVEMENT_FAIL = "ADD_MOVEMENT_FAIL"

/**
 * Edit MOVEMENT
 */
export const UPDATE_MOVEMENT = "UPDATE_MOVEMENT"
export const UPDATE_MOVEMENT_SUCCESS = "UPDATE_MOVEMENT_SUCCESS"
export const UPDATE_MOVEMENT_FAIL = "UPDATE_MOVEMENT_FAIL"

/**
 * Delete MOVEMENT
 */
export const DELETE_MOVEMENT = "DELETE_MOVEMENT"
export const DELETE_MOVEMENT_SUCCESS = "DELETE_MOVEMENT_SUCCESS"
export const DELETE_MOVEMENT_FAIL = "DELETE_MOVEMENT_FAIL"


/* ANIMALS */
export const GET_ANIMALS_BY_FARM = "GET_ANIMALS_BY_FARM"
export const GET_ANIMALS_BY_FARM_SUCCESS = "GET_ANIMALS_BY_FARM_SUCCESS"
export const GET_ANIMALS_BY_FARM_FAIL = "GET_ANIMALS_BY_FARM_FAIL"



/**
 * add MONITORING
 */
export const ADD_NEW_MONITORING = "ADD_NEW_MONITORING"
export const ADD_MONITORING_SUCCESS = "ADD_MONITORING_SUCCESS"
export const ADD_MONITORING_FAIL = "ADD_MONITORING_FAIL"

/**
 * IMPORT_MOVEMENT MOVEMENT
 */
 export const IMPORT_MOVEMENT = "IMPORT_MOVEMENT"
 export const IMPORT_MOVEMENT_SUCCESS = "IMPORT_MOVEMENT_SUCCESS"
 export const IMPORT_MOVEMENT_FAIL = "IMPORT_MOVEMENT_FAIL"

import React from 'react';
import { Redirect } from 'react-router-dom';

// Authentication related pages
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import Register from '../pages/Authentication/Register';
import ForgetPwd from '../pages/Authentication/ForgetPassword';

//  // Inner Authentication
import Login1 from '../pages/AuthenticationInner/Login';
import Login2 from '../pages/AuthenticationInner/Login2';
import Register1 from '../pages/AuthenticationInner/Register';
import Register2 from '../pages/AuthenticationInner/Register2';
import Recoverpw from '../pages/AuthenticationInner/Recoverpw';
import Recoverpw2 from '../pages/AuthenticationInner/Recoverpw2';
import ForgetPwd1 from '../pages/AuthenticationInner/ForgetPassword';
import ForgetPwd2 from '../pages/AuthenticationInner/ForgetPassword2';
import LockScreen from '../pages/AuthenticationInner/auth-lock-screen';
import LockScreen2 from '../pages/AuthenticationInner/auth-lock-screen-2';
import ConfirmMail from '../pages/AuthenticationInner/page-confirm-mail';
import ConfirmMail2 from '../pages/AuthenticationInner/page-confirm-mail-2';
import EmailVerification from '../pages/AuthenticationInner/auth-email-verification';
import EmailVerification2 from '../pages/AuthenticationInner/auth-email-verification-2';
import TwostepVerification from '../pages/AuthenticationInner/auth-two-step-verification';
import TwostepVerification2 from '../pages/AuthenticationInner/auth-two-step-verification-2';

// Dashboard
import Dashboard from '../pages/Dashboard/index';

//Pages
import PagesMaintenance from '../pages/Utility/pages-maintenance';
import PagesComingsoon from '../pages/Utility/pages-comingsoon';
import Pages404 from '../pages/Utility/pages-404';
import Pages500 from '../pages/Utility/pages-500';

//Contacts
// import ContactsGrid from "../pages/Contacts/contacts-grid"
// import ContactsList from "../pages/Contacts/ContactList/contacts-list"
// import ContactsProfile from "../pages/Contacts/ContactsProfile/contacts-profile"

//Form
import Farm from '../pages/Farm/index';
import Breed from 'pages/Breed/index';
import Iron from 'pages/Iron/index';
import MovementTypes from 'pages/MovementTypes/index';
import Movements from 'pages/Movements/index';
import MovementsCreate from 'pages/Movements/create';
import MovementsExit from 'pages/Movements/exit';
import MovementsTransfer from 'pages/Movements/transfer';
import Monitoring from 'pages/Movements/monitoring';
import Landing from '../pages/Sales/Landing/index';

import Tenant from '../pages/Tenant/index';
import Plan from '../pages/Plan/index';
import PlanDetails from 'pages/Plan/details';
import Profile from '../pages/Profile/index';
import Permission from '../pages/Permission/index';
import Roles from '../pages/Roles/index';
import User from '../pages/User/index';
import ModalPermissions from '../pages/Profile/ModalPermissions';
import ModalRolePermissions from '../pages/Roles/ModalRolePermissions';
import ModalRoleUser from '../pages/User/ModalRoleUser';
import KpiCard from 'pages/KpiCard';
import AnalyticGains from 'pages/Reports/Gain/AnalyticGains';
import SyntheticGains from 'pages/Reports/Gain/SyntheticGains';
import Inventory from 'pages/Reports/Gain/Inventory';
import ImportMovements from 'pages/Movements/ImportMovements';
import ModalProfiles from 'pages/Plan/ModalProfiles';
import ProgressAnalysis from 'pages/Reports/ProgressAnalysis';
import EarringAnalysis from 'pages/Reports/EarringAnalysis';
import Assinatura from 'components/Assinatura';
import Checkout from 'pages/Checkout';

const authProtectedRoutes = [
  { path: '/farms', component: Farm },
  { path: '/breeds', component: Breed },
  { path: '/irons', component: Iron },
  { path: '/movement-types', component: MovementTypes },
  { path: '/movements', component: Movements },
  { path: '/movements/create', component: MovementsCreate },
  { path: '/movements/exit', component: MovementsExit },
  { path: '/movements/transfer', component: MovementsTransfer },
  { path: '/movements/monitoring', component: Monitoring },
  { path: '/tenants', component: Tenant },
  { path: '/plans', component: Plan },
  { path: '/plans/:url/details', component: PlanDetails },
  { path: '/profiles', component: Profile },
  { path: '/permissions', component: Permission },
  { path: '/roles', component: Roles },
  { path: '/users', component: User },
  { path: '/movements/import', component: ImportMovements },

  { path: '/profiles/:id/permissions', component: ModalPermissions },
  { path: '/plans/:id/profiles', component: ModalProfiles },
  { path: '/roles/:id/permissions', component: ModalRolePermissions },

  { path: '/profiles/:id/permissions', component: ModalPermissions },
  { path: '/roles/:id/permissions', component: ModalRolePermissions },
  { path: '/users/:id/roles', component: ModalRoleUser },
  { path: '/kpi-cards', component: KpiCard },
  { path: '/reports/analytic-gains', component: AnalyticGains },
  { path: '/reports/analytic-gains/progress-analysis', component: ProgressAnalysis },
  { path: '/reports/synthetic-gains', component: SyntheticGains },
  { path: '/reports/synthetic-gains/earring-analysis', component: EarringAnalysis },
  { path: '/assinatura', component: Assinatura },
  { path: '/dashboard', component: Dashboard },

  { path: '/reports/inventory', component: Inventory },
  { path: '/', exact: true, component: () => <Redirect to="/dashboard" /> },
  { path: '*', component: Pages404 }
];

const publicRoutes = [
  { path: '/landing', component: Landing },
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },

  { path: '/forgot-password', component: ForgetPwd },
  { path: '/checkout/:planUrl', component: Checkout },
  { path: '/checkout/:planUrl/:slug', component: Checkout },
  { path: '/register/:id', component: Register },

  { path: '/pages-maintenance', component: PagesMaintenance },
  { path: '/pages-comingsoon', component: PagesComingsoon },
  { path: '/pages-500', component: Pages500 },

  // Authentication Inner
  { path: '/pages-login', component: Login1 },
  { path: '/pages-login-2', component: Login2 },
  { path: '/pages-register', component: Register1 },
  { path: '/pages-register-2', component: Register2 },
  { path: '/page-recoverpw', component: Recoverpw },
  { path: '/page-recoverpw-2', component: Recoverpw2 },
  { path: '/pages-forgot-pwd', component: ForgetPwd1 },
  { path: '/auth-recoverpw-2', component: ForgetPwd2 },
  { path: '/auth-lock-screen', component: LockScreen },
  { path: '/auth-lock-screen-2', component: LockScreen2 },
  { path: '/page-confirm-mail', component: ConfirmMail },
  { path: '/page-confirm-mail-2', component: ConfirmMail2 },
  { path: '/auth-email-verification', component: EmailVerification },
  { path: '/auth-email-verification-2', component: EmailVerification2 },
  { path: '/auth-two-step-verification', component: TwostepVerification },
  { path: '/auth-two-step-verification-2', component: TwostepVerification2 }
];

export { authProtectedRoutes, publicRoutes };


import React from 'react'
import { Button } from 'reactstrap'

import PciImage from '../../../../assets/images/pci.png'
import useCheckoutStore from 'zustand/checkout'



const Footer = ({ textButton, buttonPrevius, meta }) => {

  const { step, setStep, maxStep } = useCheckoutStore()

  const handleNext = () => {
    if(meta.error) {
      return
    }

    if (step >= maxStep) {
      return setStep(step)
    }

    return setStep(step + 1)

  }

  const handlePrevious = () => {
    if (step <= 0) {

      return setStep(0)
    }

    return setStep(step - 1)
  }

 
  return (
    <div color="#fff" style={{ marginTop: "3.85rem", display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
      <div href="/subscription">
        <img src={PciImage} alt='Logo' />
      </div>
      <div style={{ display: 'flex', justifyContent: "flex-end", alignItems: 'center', flexDirection: 'row' }} >
        <div
          className='text-primary'
          onClick={handlePrevious}
          style={{
            marginRight: '1.75rem',
            cursor: 'pointer'
          }}>
          {`${buttonPrevius}`}
        </div>
        <Button style={{ background: '#005789' }}>
          {textButton}
        </Button>

      </div>
    </div>
  )
}

export default Footer
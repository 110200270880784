import { call, put, takeEvery } from "redux-saga/effects";

// Iron Redux States
import {
  GET_IRONS,
  ADD_NEW_IRON,
  UPDATE_IRON,
  DELETE_IRON,
} from "./actionTypes";
import {
  getIronsFail,
  getIronsSuccess,
  addIronFail,
  addIronSuccess,
  updateIronSuccess,
  updateIronFail,
  deleteIronSuccess,
  deleteIronFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getIrons,
  addNewIron,
  updateIron,
  deleteIron,
} from "helpers/backend_fazenda_helper";

function* onGetIrons() {
  try {
    const response = yield call(getIrons);
    yield put(getIronsSuccess(response.data));
  } catch (error) {
    yield put(getIronsFail(error));
  }
}

function* onUpdateIron({ payload: iron }) {
  try {
    const response = yield call(updateIron, iron);
    yield put(updateIronSuccess(response.data));
  } catch (error) {
    yield put(updateIronFail(error));
  }
}

function* onDeleteIron({ payload: iron }) {
  try {
    const response = yield call(deleteIron, iron);
    console.log("response", response)
    yield put(deleteIronSuccess(response.data));
  } catch (error) {
    console.log("error", error)
    yield put(deleteIronFail(error));
  }
}

function* onAddNewIron({ payload: iron }) {
  try {
    const response = yield call(addNewIron, iron);
    yield put(addIronSuccess(response.data));
  } catch (error) {
    yield put(addIronFail(error));
  }
}

function* ironSaga() {
  yield takeEvery(GET_IRONS, onGetIrons);
  yield takeEvery(ADD_NEW_IRON, onAddNewIron);
  yield takeEvery(UPDATE_IRON, onUpdateIron);
  yield takeEvery(DELETE_IRON, onDeleteIron);
}

export default ironSaga;

import {
  GET_PLANS_DETAILS,
  GET_PLANS_DETAILS_FAIL,
  GET_PLANS_DETAILS_SUCCESS,
  ADD_NEW_PLAN_DETAILS,
  ADD_PLAN_DETAILS_SUCCESS,
  ADD_PLAN_DETAILS_FAIL,
  UPDATE_PLAN_DETAILS,
  UPDATE_PLAN_DETAILS_SUCCESS,
  UPDATE_PLAN_DETAILS_FAIL,
  DELETE_PLAN_DETAILS,
  DELETE_PLAN_DETAILS_SUCCESS,
  DELETE_PLAN_DETAILS_FAIL,

} from "./actionTypes"

export const getPlanDetails = (planUrl) => ({
  type: GET_PLANS_DETAILS,
  planUrl: planUrl,
})

export const getPlanDetailsSuccess = planDetails => ({
  type: GET_PLANS_DETAILS_SUCCESS,
  payload: planDetails,
})

export const getPlanDetailsFail = error => ({
  type: GET_PLANS_DETAILS_FAIL,
  payload: error,
})

export const addNewPlanDetail = (planUrl, planDetails) => ({
  type: ADD_NEW_PLAN_DETAILS,
  planUrl: planUrl,
  payload: planDetails,
})

export const addPlanDetailSuccess = planDetails => ({
  type: ADD_PLAN_DETAILS_SUCCESS,
  payload: planDetails,
})

export const addPlanDetailFail = error => ({
  type: ADD_PLAN_DETAILS_FAIL,
  payload: error,
})

export const updatePlanDetail = (planUrl, planDetails) => ({
  type: UPDATE_PLAN_DETAILS,
  planUrl: planUrl,
  payload: planDetails,
})

export const updatePlanDetailSuccess = planDetails => ({
  type: UPDATE_PLAN_DETAILS_SUCCESS,
  payload: planDetails,
})

export const updatePlanDetailFail = error => ({
  type: UPDATE_PLAN_DETAILS_FAIL,
  payload: error,
})

export const deletePlanDetail = (planUrl, planDetails) => ({
  type: DELETE_PLAN_DETAILS,
  planUrl: planUrl,
  payload: planDetails,
})

export const deletePlanDetailSuccess = planDetails => ({
  type: DELETE_PLAN_DETAILS_SUCCESS,
  payload: planDetails,
})

export const deletePlanDetailFail = error => ({
  type: DELETE_PLAN_DETAILS_FAIL,
  payload: error,
})


import Cookie from 'js-cookie';

/**
 * Uuid v4 generate
 * @return {string}
 */
export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16 || 0,
      v = c === 'x' ? r : (r && 0x3) || 0x8;
    return v.toString(16);
  });
}

/**
 * Get access guestId from js cookie
 *
 * Este guest_id é enviado nas requisições da
 * api no parâmetro header.
 * @return {*}
 */
export function guestId() {
  const uniqueAccessId = Cookie.get('guest_uuid');
  if (!uniqueAccessId) {
    return Cookie.set('guest_uuid', uuidv4(), { expires: 365 });
  }
  return uniqueAccessId;
}

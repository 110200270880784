import React, { useEffect, useState, useRef } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import * as moment from 'moment';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  Input,
  FormFeedback,
  Label,
  Form,
} from 'reactstrap';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';

import Message from 'components/Message';

import { Table } from 'react-super-responsive-table';

//Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb';

import DeleteModal from '../../components/Common/DeleteModal';
import {
  getMovementTypes as onGetMovementTypes,
  addNewMovementType as onAddNewMovementType,
  updateMovementType as onUpdateMovementType,
  deleteMovementType as onDeleteMovementType,
} from 'store/movement-types/actions';

import { showMessage, hideMessage } from 'store/message/actions';

//redux
import { useSelector, useDispatch } from 'react-redux';
import ActionTable from 'components/ActionTable';
import Can from 'components/Can';
import { withTranslation } from 'react-i18next';

const MovementTypes = (props) => {
  const dispatch = useDispatch();

  const { movementTypes } = useSelector((state) => ({
    movementTypes: state.MovementType.movementTypes,
  }));

  const { error } = useSelector((state) => ({ error: state.MovementType.error }));
  const [messageAlert, setMessageAlert] = useState('');
  const [typeMessage, setTypeMessage] = useState('');

  const [modal, setModal] = useState(false);
  const [movementTypeList, setMovementTypeList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [movementType, setMovementType] = useState(null);

  useEffect(() => {
    if (error != null && Object.keys(error).length !== 0) {
      showingMessage(error.message, 'warning');
    }
  }, [error]);

  useEffect(() => {
    if (movementTypes && !movementTypes.length) {
      dispatch(onGetMovementTypes());
    }
  }, [dispatch, movementTypes]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (movementType && movementType.name) || '',
      operation: (movementType && movementType.operation) || '',
      active:
        movementType && movementType.active == 1
          ? 1
          : movementType && movementType.active == 0
          ? 0
          : 1,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please Enter With MovementType Name'),
      operation: Yup.string().required('Please Enter With MovementType operation'),
      active: Yup.boolean().required('Please Enter Your Active'),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateMovementType = {
          id: movementType ? movementType.id : 0,
          name: values.name,
          operation: values.operation,
          active: values.active,
        };
        // update movementType
        dispatch(onUpdateMovementType(updateMovementType));
        validation.resetForm();
        handleAsyncActions('Updated');
      } else {
        const newMovementType = {
          name: values['name'],
          operation: values['operation'],
          active: values['active'],
        };
        // save new movementType
        dispatch(onAddNewMovementType(newMovementType));
        validation.resetForm();
        handleAsyncActions('Added');
      }
      toggle();
    },
  });

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: movementTypes.length, // replace later with size(orders),
    custom: true,
  };

  const MovementTypeColumns = [
    {
      text: 'id',
      dataField: 'id',
      sort: true,
      // Remover a linha hidden: true para garantir que o ID seja exibido
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      dataField: 'name',
      text: `${props.t('name')}`,
      sort: true,
    },
    {
      dataField: 'operation',
      text: `${props.t('operation')}`,

      sort: true,
    },
    {
      dataField: 'active',
      text: `${props.t('active')}`,
      sort: true,
      formatter: (cellContent, row) => (row.active == 1 ? <>Ativo</> : <>Inativo</>),
    },
    {
      dataField: 'action',
      isDummyField: true,
      text: `${props.t('action')}`,

      formatter: (_cellContent, data) => (
        <ActionTable
          editPermissions={['edit_movement_types']}
          deletePermissions={['destroy_movement_types']}
          handleDelete={() => handleDeleteMovementType(data)}
          handleEdit={() => handleMovementTypeClick(data)}
        />
      ),
    },
  ];

  const toggle = () => {
    if (modal) {
      setModal(false);
      setMovementType(null);
    } else {
      setModal(true);
    }
  };

  const handleMovementTypeClick = (arg) => {
    const movementType = arg;

    setMovementType({
      id: movementType.id,
      name: movementType.name,
      operation: movementType.operation,
      movementType: movementType.active,
    });

    setIsEdit(true);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const handleDeleteMovementType = (data) => {
    if (data.id) {
      dispatch(onDeleteMovementType(data));
      onPaginationPageChange(1);

      handleAsyncActions('Deleted');
    }
  };

  const { SearchBar } = Search;

  const showingMessage = (title, type) => {
    setMessageAlert(title);
    setTypeMessage(type);

    dispatch(showMessage());
    setTimeout(() => {
      dispatch(hideMessage());
    }, 2000);
  };

  const handleAsyncActions = (action) => {
    if (error == null || Object.keys(error).length === 0) {
      showingMessage(`Movement Type ${action} Successfully`, 'success');
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setMovementTypeList(
      movementTypes.filter((movementType) =>
        Object.keys(movementType).some((key) =>
          movementType[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    );
  };

  const handleMovementTypeClicks = () => {
    setMovementTypeList('');
    setIsEdit(false);
    toggle();
  };

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'asc',
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Message title={messageAlert} type={typeMessage} />

        <MetaTags>
          <title>MovementType | SistemasW Fazenda</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title={props.t('MovementType')} breadcrumbItem={props.t('MovementType')} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={MovementTypeColumns}
                    data={movementTypes}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={movementTypes || []}
                        columns={MovementTypeColumns}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Can permissions={['create_movement_types']}>
                                    <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded  mb-2 me-2"
                                      onClick={handleMovementTypeClicks}
                                    >
                                      <i className="mdi mdi-plus me-1" />
                                      {props.t('NewMovementType')}
                                    </Button>
                                  </Can>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                            <div className="float-sm-end">
                                <SizePerPageDropdownStandalone {...paginationProps} />
                              </div>
                              <Col xl="12">
                                <div
                                  className="table-responsive mb-0"
                                  data-pattern="priority-columns"
                                >
                                  <Table id="farms-1">
                                    <BootstrapTable
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={'table table-striped table-bordered'}
                                      keyField="id"
                                      {...toolkitProps.baseProps}
                                      onTableChange={handleTableChange}
                                      {...paginationTableProps}
                                      ref={node}
                                    />
                                  </Table>
                                </div>
                                <Modal isOpen={modal} toggle={toggle}>
                                  <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit ? 'Edit Movement Type' : 'Add Movement Type'}
                                  </ModalHeader>
                                  <ModalBody>
                                    <Form
                                      onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                      }}
                                    >
                                      <Row>
                                        <Col className="col-12">
                                          <div className="mb-3">
                                            <Label className="form-label">Nome</Label>
                                            <Input
                                              name="name"
                                              type="text"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.name || ''}
                                              invalid={
                                                validation.touched.name && validation.errors.name
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.name && validation.errors.name ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.name}
                                              </FormFeedback>
                                            ) : null}
                                          </div>

                                          <div className="mb-3">
                                            <Label className="form-label">Tipo</Label>
                                            <Input
                                              name="operation"
                                              type="select"
                                              className="form-select"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.operation || ''}
                                              invalid={
                                                validation.touched.operation &&
                                                validation.errors.operation
                                                  ? true
                                                  : false
                                              }
                                            >
                                              <option value="">Selecione um tipo</option>
                                              <option value={'E'}>E - ENTRADA</option>
                                              <option value={'S'}>S - SAIDA</option>
                                              <option value={'TE'}>
                                                TE - TRANSFERÊNCIA ENTRADA
                                              </option>
                                              <option value={'TS'}>TS - TRANSFERÊNCIA SAIDA</option>
                                              <option value={'AV'}>AV - AVALIAÇÃO</option>
                                            </Input>
                                            {validation.touched.operation &&
                                            validation.errors.operation ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.operation}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                        </Col>

                                        <Col className="col-12">
                                          <div className="mb-3">
                                            <div className="mb-3">
                                              <Label className="form-label">Status</Label>
                                              <Input
                                                name="active"
                                                type="select"
                                                className="form-select"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.active || 0}
                                                invalid={
                                                  validation.touched.active &&
                                                  validation.errors.active
                                                    ? true
                                                    : false
                                                }
                                              >
                                                <option value="">{props.t('Select')}</option>
                                                <option value={1}>{props.t('Active')}</option>
                                                <option value={0}>{props.t('Inactive')}</option>
                                              </Input>
                                              {validation.touched.active &&
                                              validation.errors.active ? (
                                                <FormFeedback type="invalid">
                                                  {validation.errors.active}
                                                </FormFeedback>
                                              ) : null}
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="text-end">
                                            <button
                                              type="submit"
                                              className="btn btn-success save-movementType"
                                            >
                                              {props.t('Save')}
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Form>
                                  </ModalBody>
                                </Modal>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone {...paginationProps} />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

MovementTypes.propTypes = {
  movementTypes: PropTypes.array,
  onGetMovementTypes: PropTypes.func,
  onAddNewMovementType: PropTypes.func,
  onDeleteMovementType: PropTypes.func,
  onUpdateMovementType: PropTypes.func,
};

export default withTranslation()(MovementTypes);

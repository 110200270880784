import React, { createRef, useRef, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Title from '../Title';
import { FormGroup, Input } from 'reactstrap';
import Footer from '../Footer';
import InputComponent from '../InputComponent';
import useCheckoutStore from 'zustand/checkout';
import ReactInputMask from 'react-input-mask';

const BillingAddress = () => {
  const [disableInputs, setDisableInputs] = useState(true);
  const { setAddress, setStep, address } = useCheckoutStore();
  const handleSubmit = (data, { setSubmitting }) => {
    setAddress(data);
    setStep(3);
  };
  const refNumber = useRef(null);
  const refStreet = useRef(null);

  // Esquema de validação usando Yup
  const validationSchema = Yup.object().shape({
    cep: Yup.string().required('CEP é obrigatório'),
    street: Yup.string().required('Rua é obrigatória'),
    neighborhood: Yup.string().required('Bairro é obrigatório'),
    city: Yup.string().required('Cidade é obrigatória'),
    state: Yup.string().required('Estado é obrigatório'),
    number: Yup.string().required('Número é obrigatório'),
    complement: Yup.string()
  });

  const handleCepChange = async (e, setFieldValue) => {
    const resetFieldValues = () => {
      setFieldValue('street', '');
      setFieldValue('neighborhood', '');
      setFieldValue('city', '');
      setFieldValue('state', '');
    };

    // Remove caracteres não numéricos
    const cep = e.target.value;
    setFieldValue('cep', cep);
    const cepWithFormat = e.target.value.replace(/\D/g, '');
    if (cepWithFormat.length === 8) {
      try {
        const response = await fetch(`https://viacep.com.br/ws/${cepWithFormat}/json`);
        if (!response.ok) {
          throw new Error('CEP não encontrado');
        }
        const data = await response.json();
        if (data.erro) {
          throw new Error('CEP não encontrado');
        }
        setFieldValue('street', data.logradouro || '');
        setFieldValue('neighborhood', data.bairro || '');
        setFieldValue('city', data.localidade || '');
        setFieldValue('state', data.uf || '');

        setTimeout(() => {
          refNumber.current?.focus();
        }, 200);
        setDisableInputs(true);
      } catch (error) {
        setDisableInputs(false);
        resetFieldValues();
        setTimeout(() => {
          refStreet.current?.focus();
        }, 200);
        console.error('Erro ao buscar endereço:', error.message);
      }
    }

    return cep;
  };

  return (
    <div style={{ marginTop: '2rem', width: '100%' }}>
      <Title
        title="Endereço de cobrança"
        subTitle="Endereço de cobrança, dono do cartão e ou responsável pela empresa."
      />

      <Formik
        initialValues={address}
        onSubmit={handleSubmit}
        validationSchema={validationSchema} // Adiciona o esquema de validação
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <FormGroup>
              <Field name="cep">
                {({ field }) => (
                  <div>
                    <ReactInputMask
                      {...field}
                      mask="99.999-999"
                      onChange={(e) => handleCepChange(e, setFieldValue)}>
                      {(inputProps) => <Input {...inputProps} placeholder="CEP" type="text" />}
                    </ReactInputMask>
                    <ErrorMessage name="cep" component="div" className="error" />
                    <small style={{ marginTop: '7px', marginLeft: '8px' }}>
                      CEP para autocompletar seu endereço e informar sua localização
                    </small>
                  </div>
                )}
              </Field>
            </FormGroup>

            <FormGroup>
              <Field name="street">
                {({ field }) => (
                  <Input
                    {...field}
                    innerRef={refStreet}
                    readOnly={disableInputs}
                    style={{ backgroundColor: disableInputs ? '#f1f1f1' : '#ffffff' }}
                    placeholder="Rua, logradouro"
                    type="text"
                  />
                )}
              </Field>
              <ErrorMessage name="street" component="div" className="error" />
            </FormGroup>

            <FormGroup>
              <Field name="neighborhood">
                {({ field }) => (
                  <Input
                    {...field}
                    readOnly={disableInputs}
                    style={{ backgroundColor: disableInputs ? '#f1f1f1' : '#ffffff' }}
                    placeholder="Bairro"
                    type="text"
                  />
                )}
              </Field>
              <ErrorMessage name="neighborhood" component="div" className="error" />
            </FormGroup>

            <FormGroup>
              <Field name="city">
                {({ field }) => (
                  <Input
                    {...field}
                    readOnly={disableInputs}
                    style={{ backgroundColor: disableInputs ? '#f1f1f1' : '#ffffff' }}
                    placeholder="Cidade"
                    type="text"
                  />
                )}
              </Field>
              <ErrorMessage name="city" component="div" className="error" />
            </FormGroup>

            <FormGroup>
              <Field name="state">
                {({ field }) => (
                  <Input
                    {...field}
                    readOnly={disableInputs}
                    style={{ backgroundColor: disableInputs ? '#f1f1f1' : '#ffffff' }}
                    placeholder="Estado"
                    type="text"
                  />
                )}
              </Field>
              <ErrorMessage name="state" component="div" className="error" />
            </FormGroup>

            <FormGroup>
              <Field name="number">
                {({ field }) => (
                  <Input
                    {...field}
                    innerRef={refNumber}
                    placeholder="Número da localização"
                    type="number"
                  />
                )}
              </Field>
              <ErrorMessage name="number" component="div" className="error" />
            </FormGroup>

            <FormGroup>
              <Field name="complement">
                {({ field }) => <Input {...field} placeholder="Complemento" type="text" />}
              </Field>
              <ErrorMessage name="complement" component="div" className="error" />
            </FormGroup>

            <Footer textButton="Continuar" buttonPrevius={'<- Voltar'} disabled={isSubmitting} />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BillingAddress;

import {
  GET_MOVEMENTS_SUCCESS,
  GET_MOVEMENTS_FAIL,
  ADD_MOVEMENT_SUCCESS,
  ADD_MOVEMENT_FAIL,
  UPDATE_MOVEMENT_SUCCESS,
  UPDATE_MOVEMENT_FAIL,
  DELETE_MOVEMENT_SUCCESS,
  DELETE_MOVEMENT_FAIL,

  GET_ANIMALS_BY_FARM_FAIL,
  GET_ANIMALS_BY_FARM_SUCCESS,

    ADD_MONITORING_SUCCESS,
    ADD_MONITORING_FAIL,

    IMPORT_MOVEMENT_SUCCESS,
    IMPORT_MOVEMENT_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  movements: [],
  file: null,
  animals: [],
  error: {},
};

const Movement = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_MOVEMENTS_SUCCESS:
      return {
        ...state,
        movements: action.payload,
      };

    case GET_MOVEMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_MOVEMENT_SUCCESS:
      return {
        ...state,
        movements: [...state.movements, action.payload],
      };

    case ADD_MOVEMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_MOVEMENT_SUCCESS:
      return {
        ...state,
        movements: state.movements.map(movement =>
          movement.id.toString() === action.payload.id.toString()
            ? { movement, ...action.payload }
            : movement
        ),
      };

    case UPDATE_MOVEMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_MOVEMENT_SUCCESS:
      return {
        ...state,
        movements: state.movements.filter(
          movement => movement.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_MOVEMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      };


      case GET_ANIMALS_BY_FARM_SUCCESS:
        return {
          ...state,
          animals: action.payload,
        };

      case GET_ANIMALS_BY_FARM_FAIL:
        return {
          ...state,
          error: action.payload,
        };

      case ADD_MONITORING_SUCCESS:
          return {
              ...state,
              movements: [...state.movements, action.payload],
          };

      case ADD_MONITORING_FAIL:
          return {
              ...state,
              error: action.payload,
          };

          case IMPORT_MOVEMENT_SUCCESS:
            return {
              ...state,
              file: action.payload,
            };

          case IMPORT_MOVEMENT_FAIL:
            return {
              ...state,
              error: action.payload,
            };

    default:
      return state;
  }
};

export default Movement;

import accessToken from './../../../helpers/jwt-token-access/accessToken';

export const getToken = () => accessToken;

export const login = (token) => {
  localStorage.setItem('token', token);
};

export const setLocalUser = (user) => {
  localStorage.setItem('authUser', user);
};

export const getLocalUser = () => {
  return localStorage.getItem('user') && JSON.parse(localStorage.getItem('authUser')).data;
};

export const logout = () => {
  localStorage.removeItem('authUser');
  localStorage.removeItem('token');
  localStorage.removeItem('user_id');
  localStorage.removeItem('cover_image');
  localStorage.removeItem('photo');
};

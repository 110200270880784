import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import Title from '../Title';
import Footer from '../Footer';
import PlanCard from '../Card';
import { FormGroup, Input, InputGroup } from 'reactstrap';
import useCheckoutStore from 'zustand/checkout';
import ReactInputMask from 'react-input-mask';

const ConfirmationAndSign = () => {
  const { workspace, personalData, address, payment } = useCheckoutStore();
  const [cardType, setCardType] = useState('');
  const cardInputRef = useRef(null);

  useEffect(() => {
    if (payment.type !== 'boleto' && cardInputRef.current) {
      handleCardNumberChange({ target: { value: payment.cardNumber } });
    }
  }, [payment]);

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
  };

  const formatCardNumber = (cardNumber) => {
    const firstSixDigits = cardNumber.slice(0, 9);
    const remainingDigits = cardNumber.slice(10).replace(/\d/g, 'X');
    return `${firstSixDigits}-${remainingDigits}`;
  };

  const cardTypeRegex = {
    visa: /^4/,
    mastercard: /^5[1-5]/,
    amex: /^3[47]/,
    discover: /^6(?:011|5)/,
    diners: /^3(?:0[0-5]|[68])/,
    jcb: /^(?:2131|1800|35)/,
    elo: /^636[0-9]{13,16}$/
  };

  const handleCardNumberChange = (e) => {
    const cardNumber = e.target.value;
    let type = null;
    for (const [card, regex] of Object.entries(cardTypeRegex)) {
      if (regex.test(cardNumber)) {
        type = card;
        break;
      }
    }
    setCardType(type);
  };
  return (
    <div style={{ marginTop: '2rem', width: '100%' }}>
      <Title
        title="Confirme sua assinatura"
        subTitle="Agora confirme a sua assinatura para prosseguirmos."
      />
      <Formik
        initialValues={{
          ...payment,
          ...address,
          ...personalData,
          ...workspace
        }}
        onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <div>
              <InputGroup>
                {payment.type === 'boleto' ? (
                  'Boleto bancário'
                ) : (
                  <Field name="CardInfo">
                    {({ field }) => (
                      <ReactInputMask
                        {...field}
                        mask="9999-****-****-****"
                        maskChar=" "
                        name="cardNumber"
                        maxLength={16}
                        onChange={(e) => {
                          handleCardNumberChange(e);
                          field.onChange(e);
                        }}
                        readOnly
                        value={formatCardNumber(payment.cardNumber)}
                        placeholder="4444-****-****-****"
                        ref={cardInputRef}>
                        {(inputProps) => (
                          <Input
                            {...inputProps}
                            style={{
                              marginTop: '1rem',
                              width: '100%',
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'start',
                              borderRadius: '7px',
                              height: '2rem',
                              paddingLeft: '0.7rem',
                              border: '1px solid #ccc',
                              backgroundImage: cardType
                                ? `url(/creditCards/${cardType}.png)`
                                : 'none',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'right center',
                              backgroundSize: 'contain'
                            }}
                            type="text"
                          />
                        )}
                      </ReactInputMask>
                    )}
                  </Field>
                )}
              </InputGroup>
            </div>
            <FormGroup>
              <div style={{ marginTop: '1rem' }}>
                <strong style={{ color: '#000' }}>Endereço</strong>
                <br />
                <p>{`${address.street}, ${address.number}, ${address.neighborhood}, ${address.city}, ${address.state}, CEP: ${address.cep}`}</p>
              </div>
              <div style={{ marginTop: '1rem' }}>
                <strong style={{ color: '#000' }}>Nome do espaço</strong>
                <br />
                <p>{`${workspace.workspaceName}.wsharefazendas.com.br`}</p>
              </div>
              <div style={{ marginTop: '1rem' }}>
                <strong style={{ color: '#000' }}>Seus dados</strong>
                <br />
                <p>{`${personalData.fullName} / ${personalData.email} / ${personalData.cpf}`}</p>
              </div>
            </FormGroup>
            <PlanCard
              title="Plano smart 500"
              item1="500 Bois"
              item2="100 Brincos"
              item3="Até 10 Usuários"
              text="Valor total do plano R$ 599,50 / mês"
            />
            <Footer
              textButton="Finalizar e assinar plano"
              buttonPrevius={'<- Voltar'}
              disabled={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ConfirmationAndSign;

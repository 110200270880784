import React, { useState, useMemo, memo } from 'react';
import { Input, Tooltip } from 'reactstrap';
import ModalZoomItem from './MediaZoomItem';
import useMediaLibraryStore from 'zustand/media-library/file-upload-store';
import { fileInfo } from './_helpers/file-media';

export default function MediaItem({
  imageName = '',
  size = '',
  fileDatabaseId = undefined, // Atualizar a imagem / excluir
  imageDescription = undefined,
  imageLastModified = undefined,
  isEditableInput = false,
  imageUrl = false
}) {
  const [isEditable, setIsEditable] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [imageZoomOpen, setImageZoomOpen] = useState(false);
  const useMediaLibrary = useMediaLibraryStore();
  const { setFileName, dataUpload } = useMediaLibrary;

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const toggleZoom = () => setImageZoomOpen(imageZoomOpen === false ? true : false);

  const item = useMemo(() => {
    const indexOfFile = dataUpload.selectedFiles.findIndex((file) => file.name === imageName);
    const file = dataUpload.selectedFiles.find((file) => file.name === imageName);

    const fileSplit = file?.name?.split('.') ?? imageName.split('.');
    const fileExtension = fileSplit?.length > 0 ? fileSplit?.pop() : '';
    let fileName = '';

    fileSplit?.map((fileSplitItem) => {
      if (fileSplitItem === fileExtension) {
        return fileSplitItem;
      }
      fileName = fileName + fileSplitItem;
      return fileSplitItem;
    });

    const backgroundStyle = {
      height: 'auto',
      with: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center top',
      backgroundImage: `url('${file?.url ?? imageUrl}')`
    };

    return { indexOfFile, file, fileName, fileExtension, backgroundStyle };
  }, [dataUpload, imageName, setFileName]);

  const handleEdit = (event) => {
    if (item.indexOfFile !== -1) {
      setFileName(item.indexOfFile, `${event.target.value}.${item.fileExtension}`);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleEdit(event);
      setIsEditable(false);
      return false;
    }
  };

  return (
    <div className="card mb-3 p-3 bg-light text-dark">
      <ModalZoomItem
        title={item.fileName}
        imageUrl={item?.file?.url || imageUrl}
        isOpen={imageZoomOpen}
        toggleZoom={toggleZoom}
        keyboard
      />
      <div className="row g-0">
        <div onClick={toggleZoom} className="col-md-5" style={item.backgroundStyle}></div>
        <div className="col-md-7">
          <div
            className="card-body"
            style={{ verticalAlign: 'text-top', padding: 0, paddingLeft: 6 }}>
            <p
              className="card-title"
              style={{ cursor: 'text' }}
              id="editableName"
              onClick={() => setIsEditable(true)}>
              {isEditable && isEditableInput ? (
                <>
                  <Input
                    value={item.fileName}
                    id="editableValue"
                    type="text"
                    className="form-control"
                    placeholder="Enter editable value..."
                    onBlur={() => setIsEditable(false)}
                    onChange={handleEdit}
                    onKeyDown={handleKeyDown}
                    name="editableValue"
                  />
                  <Tooltip autohide isOpen={tooltipOpen} target="editableName" toggle={toggle}>
                    Clique par editar o nome da imagem
                  </Tooltip>
                </>
              ) : (
                `${item.fileName}.${item.fileExtension}`
              )}
            </p>
            <p className="card-text">
              <small>Última alteração {imageLastModified}</small>
            </p>
            <p className="card-text">
              <small className="text-muted">Tamanho {size}</small>
            </p>
            {imageDescription && <p className="card-text">{imageDescription}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}

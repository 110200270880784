import React, { useEffect } from 'react';

import { Spinner } from 'reactstrap';

import ContentImg from '../../../../assets/images/background-right-checkout.jpg';
import WorkspaceName from '../WorkspaceName';
import PersonalData from '../PersonalData';
import useCheckoutStore from 'zustand/checkout';
import useScreenSize from 'hooks/useScreenSize';
import BillingAddress from '../BillingAddress';
import Payment from '../FormOfPayment';
import ConfirmationAndSign from '../ConfirmationAndSign';
import { useParams, useHistory } from 'react-router-dom';
import { usePlansStore } from 'zustand/plans/plans-store';

const Content = () => {
  const screenSize = useScreenSize();
  const { step, indexUrls, urls, setStep } = useCheckoutStore();
  const { getByUrl } = usePlansStore();
  const { slug, planUrl } = useParams();
  const navigate = useHistory();

  useEffect(() => {
    if (slug) {
      const indexUrl = slug in indexUrls ? indexUrls[slug] : 0;

      if (indexUrl !== step) {
        navigate.push(urls[indexUrl]);
      }
    }
  }, [step, navigate]);

  useEffect(() => {
    getByUrl(planUrl).then(() => {
      if (step === -1) {
        setStep(0);
      }
    });
  }, [getByUrl, planUrl]);

  return (
    <div
      className="bg-light"
      style={{
        display: 'flex',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
      }}>
      {step === -1 && <Spinner />}

      <div
        style={{
          width: `${screenSize.width < 1100 ? '100%' : '1100px'}`,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}>
        {step === 0 && <WorkspaceName />}
        {step === 1 && <PersonalData />}
        {step === 2 && <BillingAddress />}
        {step === 3 && <Payment />}
        {step === 4 && <ConfirmationAndSign />}
        <div
          style={{
            backgroundImage: `url(${ContentImg})`,
            display: `${screenSize.width <= 900 ? 'none' : 'block'}`,
            marginLeft: '3.5rem',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right top',
            width: '100%',
            height: '100vh',
            backgroundSize: 'cover'
          }}></div>
      </div>
    </div>
  );
};

export default Content;

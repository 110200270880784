import create from 'zustand';

import axiosApi from 'helpers/api_helper';

export class FileUploadService {
  async uploadLibraries(libraries, onUploadProgress, resource, resourceId) {
    let formData = new FormData();

    formData.append('files[]', libraries);

    return axiosApi.post(`/media-library/${resource}/${resourceId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    });
  }

  getLibraries(resource, resourceId, collect) {
    return axiosApi.get(`/media-library/${resource}/${resourceId}`, {
      params: {
        collect
      }
    });
  }

  getLibrary(uuId, resource, resourceId) {
    return axiosApi.get(`/media-library/${uuId}/${resource}/${resourceId}`);
  }

  removeLibraries(resource, resourceId) {
    return axiosApi.delete(`/media-library/${resource}/${resourceId}`);
  }

  removeLibrary(uuId, resource, resourceId) {
    return axiosApi.delete(`/media-library/${uuId}/${resource}/${resourceId}`);
  }
}

export const fileUploadService = new FileUploadService();

export const useMediaLibraryStore = create((set, get) => ({
  isLoading: false,
  isLoadingFileUpload: false,
  libraries: [],
  addLibraries: async (library, onUploadProgress, resource, resourceId) => {
    set({ isLoadingFileUpload: true });
    const response = await fileUploadService.uploadLibraries(
      library,
      onUploadProgress,
      resource,
      resourceId
    );
    return set({
      libraries: [...get().libraries, ...response.data.data],
      isLoadingFileUpload: false
    });
  },
  removeLibrary: async (libraryUuid, resource, resourceId) => {
    set({ isLoading: true });
    const response = await fileUploadService.removeLibrary(libraryUuid, resource, resourceId);
    return set({ libraries: [...get().libraries, ...response.data.data], isLoading: false });
  },
  removeAllLibraries: async (resource, resourceId) => {
    set({ isLoading: true });
    const response = await fileUploadService.removeLibraries(resource, resourceId);
    return set({ libraries: [...get().libraries, ...response.data.data], isLoading: false });
  },

  getLibraries: async (resource, resourceId, collect) => {
    set({ isLoading: true });
    const response = await fileUploadService.getLibraries(resource, resourceId, collect);
    return set({ libraries: response.data.data, isLoading: false });
  },

  dataUpload: {
    selectedFiles: [],
    previewImages: [],
    progressInfos: [],
    message: [],
    imageInfos: [],
    imagesForUpload: []
  },

  setFileName: (index, fileName) => {
    const newData = { ...get().dataUpload };
    newData.selectedFiles[index].name = fileName;
    return set({ dataUpload: newData });
  },
  cleanFiles: () =>
    set({
      dataUpload: {
        selectedFiles: [],
        previewImages: [],
        progressInfos: [],
        message: [],
        imageInfos: []
      }
    }),
  setDataUpload: (dataUpload) => set({ dataUpload })
}));

export default useMediaLibraryStore;

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { isExistsModelByHostname } from 'zustand/tenant/services/api';
import { ENABLE_PATH_ROOT_URL } from 'constants/tenant';

const Authmiddleware = ({ component: Component, layout: Layout, isAuthProtected, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const pathName = window.location.pathname;

      if (
        !isExistsModelByHostname() &&
        !ENABLE_PATH_ROOT_URL.includes(`/${pathName.split('/')[1]}`)
      ) {
        return <Redirect to={{ pathname: '/landing', state: { from: props.location } }} />;
      }

      if (isAuthProtected && !localStorage.getItem('authUser')) {
        return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any
};

export default Authmiddleware;

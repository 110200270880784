import { call, put, takeEvery } from "redux-saga/effects";

// Movement Redux States
import {
  GET_MOVEMENTS,
  ADD_NEW_MOVEMENT,
  UPDATE_MOVEMENT,
  DELETE_MOVEMENT,
  GET_ANIMALS_BY_FARM,
  ADD_NEW_MONITORING,
  IMPORT_MOVEMENT,
} from "./actionTypes";
import {
  getMovementsFail,
  getMovementsSuccess,
  addMovementFail,
  addMovementSuccess,
  updateMovementSuccess,
  updateMovementFail,
  deleteMovementSuccess,
  deleteMovementFail,
  getAnimalsByFarmSuccess,
  getAnimalsByFarmFail,

  addMonitoringSuccess,
  addMonitoringFail,

  importMovementSuccess,
  importMovementFail,

} from "./actions";

//Include Both Helper File with needed methods
import {
  getMovements,
  addNewMovement,
  updateMovement,
  deleteMovement,
  getAnimalsByFarm,
  addNewMonitoring,
  importMovement,
} from "helpers/backend_fazenda_helper";

function* onGetMovements() {
  try {
    const response = yield call(getMovements);
    yield put(getMovementsSuccess(response.data));
  } catch (error) {
    yield put(getMovementsFail(error));
  }
}

function* onUpdateMovement({ payload: movement }) {
  try {
    const response = yield call(updateMovement, movement);
    yield put(updateMovementSuccess(response.data));

  } catch (error) {
    yield put(updateMovementFail(error));
  }
}

function* onDeleteMovement({ payload: movement }) {
  try {
    const response = yield call(deleteMovement, movement);
    yield put(deleteMovementSuccess(response.data));
  } catch (error) {
    yield put(deleteMovementFail(error));
  }
}

function* onAddNewMovement({ payload: movement }) {
  try {
    const response = yield call(addNewMovement, movement);
    yield put(addMovementSuccess(response.data));
  } catch (error) {
    yield put(addMovementFail(error));
  }
}


function* onGetAnimalsByFarm({ farmId = 1}) {

    try {
      const response = yield call(getAnimalsByFarm, farmId);
      yield put(getAnimalsByFarmSuccess(response.data));
    } catch (error) {
      yield put(getAnimalsByFarmFail(error));
    }
  }


function* onAddNewMonitoring({payload: movement}) {

    try {
        console.log('movement-: ', movement);


        const response = yield call(addNewMonitoring, movement);
        yield put(addMonitoringSuccess(response.data));
    } catch (error) {
        yield put(addMonitoringFail(error));
    }
}

function* onImportMovement({ payload: file }) {
    try {
        console.log('entou1', file.getAll('file'));
      const response = yield call(importMovement, file);
      yield put(importMovementSuccess(response.data));
    } catch (error) {
      yield put(importMovementFail(error));
    }
  }


function* movementSaga() {
  yield takeEvery(GET_MOVEMENTS, onGetMovements);
  yield takeEvery(ADD_NEW_MOVEMENT, onAddNewMovement);
  yield takeEvery(UPDATE_MOVEMENT, onUpdateMovement);
  yield takeEvery(DELETE_MOVEMENT, onDeleteMovement);
  yield takeEvery(GET_ANIMALS_BY_FARM, onGetAnimalsByFarm);
  yield takeEvery(ADD_NEW_MONITORING, onAddNewMonitoring);
  yield takeEvery(IMPORT_MOVEMENT, onImportMovement);


}

export default movementSaga;

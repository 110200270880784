import React from 'react';
import PropTypes from 'prop-types';
import Logo from '../../../assets/images/wshare.png';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import useStockStore from 'zustand/stocks/stocks-store';
import { createWorkbook, createWorksheet } from 'lib/exceljs/Services';
import { formatPrice } from 'utils/inputMask';
import { withTranslation } from 'react-i18next';

const ModalInventory = (props) => {
  const { isOpen, toggle, data } = props;
  const { movement_date, movement_name, farm_name } = data;
  const { movementsByCollect } = useStockStore();

  const dataHoraAtual = movement_date ? new Date(movement_date) : undefined;
  const dia = dataHoraAtual ? String(dataHoraAtual.getDate()).padStart(2, '0') : '00';
  const mes = dataHoraAtual ? String(dataHoraAtual.getMonth() + 1).padStart(2, '0') : '00';
  const ano = dataHoraAtual ? dataHoraAtual.getFullYear() : '00';

  //documentado para auxiliar nas futuras manutenções
  const handleSaveAsPDF = () => {
    const doc = new jsPDF();

    const logoImg = new Image();
    logoImg.onload = function () {
      const width = 40; // Largura desejada em pixels
      const aspectRatio = logoImg.height / logoImg.width;
      const height = width * aspectRatio;

      doc.addImage(logoImg, 'PNG', 10, 10, width, height);

      // Adiciona a data com espaçamento
      const currentDate = `${dia}/${mes}/${ano}`;
      doc.setFontSize(13); // Define o tamanho da fonte para 13px
      doc.text(`Data do movimento: ${currentDate}`, 134, 20);

      // Adiciona o título com espaçamento
      doc.text('Brincos', 105, 40, null, null, 'center');

      // Adiciona a tabela
      doc.autoTable({
        head: [['Brinco', 'Peso', 'Iron', 'Note']],
        body: movementsByCollect.map((item) => [item.earring, item.weight, item.iron, item.note]),
        startY: 50 // Posição inicial da tabela
      });

      doc.save('stock.pdf');
    };
    logoImg.src = Logo;
  };

  const handleSaveAsXlsx = () => {
    const workbook = createWorkbook();
    const worksheet = createWorksheet(workbook, `Movimentos ${movement_name} - ${farm_name}`);

    worksheet.columns = [
      { header: `${props.t('Earring')}`, key: 'earring', width: 20 },
      { header: 'Peso', key: 'weight', width: 20 },
      { header: 'Ferro', key: 'iron', width: 10 },
      { header: 'Nota', key: 'note', width: 100 }
    ];

    movementsByCollect.map((item) =>
      worksheet.addRow({
        earring: item.earring,
        weight: formatPrice(item.weight),
        iron: item.iron_name,
        note: item.note
      })
    );

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      a.href = url;
      a.download = `Movimentos ${ano}-${mes}-${dia} - ${data.farm_name}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="modal-fullscreen"
      tabIndex="-1"
      toggle={toggle}>
      <div className="modal-content">
        <ModalHeader toggle={toggle} />
        <div style={{ textAlign: 'center', marginBottom: '-1.3rem', marginTop: '0.5rem' }}>
          <h4>Stock</h4>
          <p className="farm-info">{`Data do movimento: ${dia}/${mes}/${ano}`}</p>
        </div>

        <ModalBody>
          <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
            <div className="table-responsive container">
              <Table bordered className="table align-middle table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">{props.t("Earring")}</th>
                    <th scope="col">{props.t("Weight")}</th>
                    <th scope="col">{props.t("Iron")}</th>
                    <th scope="col">{props.t("Note")}</th>
                  </tr>
                </thead>
                <tbody>
                  {movementsByCollect.map((item, index) => (
                    <tr key={index}>
                      <td>{item.earring}</td>
                      <td>{formatPrice(item.weight)}</td>
                      <td>{item.iron_name}</td>
                      <td>{item.note}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            {props.t("Close")}
          </Button>
          <Button type="button" color="primary" onClick={handleSaveAsPDF}>
            {props.t("Save as PDF")}
          </Button>
          <Button type="button" color="success" onClick={handleSaveAsXlsx}>
            {props.t("Save as XML")}
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

ModalInventory.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool
};

export default withTranslation()(ModalInventory);


import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import DeleteModal from 'components/Common/DeleteModal';
import Can from 'components/Can';

const TableAction = ({
  handleEdit,
  handleDelete,
  isEdit = true,
  isDelete = true,
  editPermissions = [],
  deletPermissions = []
}) => {
  const [isOpenDelete, setIsOpenDelete] = React.useState();
  return (
    <>
      <DeleteModal
        show={isOpenDelete}
        onDeleteClick={handleDelete}
        onCloseClick={() => setIsOpenDelete(false)}
      />

      <div className="d-flex gap-3">
        <Can permissions={editPermissions}>
          {isEdit && (
            <Link to="#" className="text-success" onClick={handleEdit}>
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>
          )}
        </Can>

        <Can permissions={deletPermissions}>
          {isDelete && (
            <Link to="#" className="text-danger" onClick={() => setIsOpenDelete(true)}>
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          )}
        </Can>
      </div>
    </>
  );
};

TableAction.propTypes = {
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  isDelete: PropTypes.bool,
  isEdit: PropTypes.bool
};

export default withRouter(TableAction);

import { call, put, takeEvery } from "redux-saga/effects";

// Ecommerce Redux States
import {
    GET_PLANS_DETAILS,
    ADD_NEW_PLAN_DETAILS,
    UPDATE_PLAN_DETAILS,
    DELETE_PLAN_DETAILS,
} from "./actionTypes";
import {
  getPlanDetailsFail,
  getPlanDetailsSuccess,
  addPlanDetailFail,
  addPlanDetailSuccess,
  updatePlanDetailSuccess,
  updatePlanDetailFail,
  deletePlanDetailSuccess,
  deletePlanDetailFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getPlanDetails,
  addNewPlanDetails,
  updatePlanDetails,
  deletePlanDetails,
} from "helpers/backend_fazenda_helper";

function* onGetPlanDetails({planUrl}) {
  try {
    const response = yield call(getPlanDetails, planUrl);
    yield put(getPlanDetailsSuccess(response.data));
  } catch (error) {
    yield put(getPlanDetailsFail(error));
  }
}

function* onUpdatePlanDetails({planUrl, payload}) {
  try {
    const response = yield call(updatePlanDetails, planUrl, payload);
    yield put(updatePlanDetailSuccess(response.data));
  } catch (error) {
    yield put(updatePlanDetailFail(error));
  }
}

function* onDeletePlanDetails({planUrl, payload}) {
  try {
    const response = yield call(deletePlanDetails, planUrl, payload);
    yield put(deletePlanDetailSuccess(response.data));
  } catch (error) {
    yield put(deletePlanDetailFail(error));
  }
}

function* onAddNewPlanDetails({planUrl, payload}) {
  try {
    const response = yield call(addNewPlanDetails, planUrl, payload);
    yield put(addPlanDetailSuccess(response.data));
  } catch (error) {
    yield put(addPlanDetailFail(error));
  }
}

function* PlanDetailsSaga() {
  yield takeEvery(GET_PLANS_DETAILS, onGetPlanDetails);
  yield takeEvery(ADD_NEW_PLAN_DETAILS, onAddNewPlanDetails);
  yield takeEvery(UPDATE_PLAN_DETAILS, onUpdatePlanDetails);
  yield takeEvery(DELETE_PLAN_DETAILS, onDeletePlanDetails);
}

export default PlanDetailsSaga;

import {
  GET_PLAN_PROFILES_SUCCESS,
  GET_PLAN_PROFILES_FAIL,

  GET_PLAN_PROFILES_AVAILABLES_SUCCESS,
  GET_PLAN_PROFILES_AVAILABLES_FAIL,

  ADD_PLAN_PROFILE_SUCCESS,
  ADD_PLAN_PROFILE_FAIL,

  DELETE_PLAN_PROFILE_SUCCESS,
  DELETE_PLAN_PROFILE_FAIL
} from "./actionTypes";

const INIT_STATE = {
  planProfiles: [],
  profilesAvailable: [],
  error: {},
};

const PlanProfiles = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_PLAN_PROFILES_SUCCESS:
      return {
        state,
        planProfiles: action.payload,
      };

    case GET_PLAN_PROFILES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_PLAN_PROFILES_AVAILABLES_SUCCESS:
      return {
        state,
        profilesAvailable: action.payload,
      };

    case GET_PLAN_PROFILES_AVAILABLES_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case ADD_PLAN_PROFILE_SUCCESS:
      return {
        ...state,
        planProfiles: [...state.planProfiles, action.payload],
      };

    case ADD_PLAN_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_PLAN_PROFILE_SUCCESS:
      return {
        ...state,
          planProfiles: state.planProfiles.filter(
          planProfiles => planProfiles.id.toString() !== action.payload.id.toString()
        ),
      };

    case DELETE_PLAN_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default PlanProfiles;

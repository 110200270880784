import PropTypes from 'prop-types';
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { FiChevronUp } from "react-icons/fi";
import './CardPricing.css';

const CardPricing = props => {
  const [isContact, setIsContact] = useState(false);

  function convertToMoney(value) {
    let floatValue = parseFloat(value);
    let formato = { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' }
    let formatedValue = floatValue.toLocaleString('pt-BR', formato);
    return formatedValue;
  }

  return (
    <div className="pricing-col">
      <Card className="plan-box pricing-card">
        <CardBody className="p-4 d-flex flex-column justify-content-between">
          <div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>
                <h5 className="pricing-title">{props.pricing.name}</h5>
                <p className="pricing-description">{props.pricing.description}</p>
              </div>
              <div className="icon">
                <i className="bx mdi mdi-cow h1 text-primary" />
              </div>
            </div>
            <div className="py-4 text-center">
              {props.pricing.price === "0.00" ? (
                <>
                  <h2 className="pricing-price">Sob Consulta</h2>
                  {isContact ? (
                    <div className="text-center">
                      <FiChevronUp type='button' color="#556ee6" size={25} onClick={() => setIsContact(!isContact)} />
                    </div>
                  ) : (
                    <div className="text-center">
                      <button type="button" onClick={() => setIsContact(!isContact)} className="btn btn-primary btn-sm mt-3">
                        Contato
                      </button>
                    </div>
                  )}
                  {isContact && (
                    <div className='text-left mt-3'>
                      <p className='font-size-15 font-medium'>E-mail: email@email.com.br</p>
                      <p className='font-size-15 font-medium'>Telefone: (11) 9999-9999</p>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <h2 className="pricing-price">{convertToMoney(props.pricing.price)} <span className="font-size-13">per month</span></h2>
                  <div className="text-center">
                    <Link to={`${props.link}${props.pricing.url}`} className="btn btn-primary btn-sm mt-3">
                      Contratar agora
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="plan-features mt-1">
            {props.pricing.details.length > 0 ? (
              props.pricing.details.map((details, key) => (
                <p key={"_feature_" + key}>
                  <i className="bx bx-checkbox-square text-primary" />{" "}
                  <span> {details.name}</span>
                </p>
              ))
            ) : (
              <p>No details in this Plan</p>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

CardPricing.propTypes = {
  pricing: PropTypes.object,
  link: PropTypes.string
}

export default CardPricing;
import React, { useEffect, useState, useRef } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';

import { Card, CardBody, Col, Container, Row } from 'reactstrap';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { Table } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider
} from 'react-bootstrap-table2-paginator';

import { formatPrice } from '../../../utils/inputMask';

import Message from 'components/Message';

//Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb';

import { getSyntheticGains as onGetSyntheticGains } from 'store/reports/gains/actions';

import { showMessage, hideMessage } from 'store/message/actions';

//redux
import { useSelector, useDispatch } from 'react-redux';
import DroopSynthetic from './Filters/DroopFilterSynthetic/DroopSynthetic';
import { withTranslation } from 'react-i18next';

const SyntheticGains = (props) => {
  const dispatch = useDispatch();

  const [itens, setItens] = useState([]);

  const { syntheticGains } = useSelector((state) => ({
    syntheticGains: state.ReportGains.syntheticGains
  }));

  const { error } = useSelector((state) => ({ error: state.ReportGains.error }));
  const [messageAlert, setMessageAlert] = useState('');
  const [typeMessage, setTypeMessage] = useState('');

  useEffect(() => {
    if (error != null && Object.keys(error).length !== 0) {
      // showingMessage(error.message, 'warning');
    }
  }, [error]);

  useEffect(() => {
    dispatch(onGetSyntheticGains());
  }, [dispatch]);

  useEffect(() => {
    if (syntheticGains && syntheticGains.length > 0) {
      setItens(syntheticGains);
    }
  }, [syntheticGains]);

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: itens.length, // replace later with size(orders),
    custom: true
  };

  const SyntheticGainColumns = [
    {
      dataField: 'collect_id',
      text: 'id',
      //   sort: true,
      hidden: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.collect_id}</>
    },
    {
      dataField: 'difference_days',
      text: `${props.t('Total measurement days')}`, //'Dias da medição total'
      //   sort: true
      formatter: (cellContent, row) => <>{row.operation === 'E' ? '0' : row.difference_days}</>
    },
    {
      dataField: 'avg_month',
      text: `${props.t('Average measurement months')}`, //'Meses da medição média',
      // sort: true
      formatter: (cellContent, row) => (
        <>{row.operation === 'E' ? '0,00' : formatPrice(row.avg_month)}</>
      )
    },
    {
      dataField: 'final_weight',
      text: `${props.t('Total weight in kg - final')}`, // 'Peso total em kg - final',
      // sort: true
      formatter: (cellContent, row) => (
        <>{row.operation === 'E' ? '0,00' : formatPrice(row.final_weight)}</>
      )
    },
    {
      dataField: 'start_weight',
      text: `${props.t('Total weight in kg - initial')}`, // 'Peso total em kg - inicial',
      //   sort: true
      formatter: (cellContent, row) => (
        <>{row.operation === 'E' ? '0,00' : formatPrice(row.start_weight)}</>
      )
    },
    {
      dataField: 'gains_kg',
      text: `${props.t('Difference final total weight minus initial total weight')}`, // 'Diferença peso total final menos peso total inicial',
      //   sort: true
      formatter: (cellContent, row) => (
        <>{row.operation === 'E' ? '0,00' : formatPrice(row.gains_kg)}</>
      )
    },

    {
      dataField: 'qtd_total_animals',
      text: `${props.t('Total number of cattle')}`, //'Quantidade de gados total',

      //  sort: true
      formatter: (cellContent, row) => <>{row.operation === 'E' ? '0' : row.qtd_total_animals}</> // alterar para intMask
    },
    {
      dataField: 'qtd_total_animals_com_brinco',
      text: `${props.t('Number of cattle with earring')}`, // 'Quantidade de gados com brinco',
      //   sort: true
      formatter: (cellContent, row) => (
        <>{row.operation === 'E' ? '0' : row.qtd_total_animals_com_brinco}</>
      )
    },
    {
      dataField: 'qtd_total_animals_sem_brinco',
      text: `${props.t('Number of cattle without earring')}`, // 'Quantidade de gados sem brinco',
      //   sort: true
      formatter: (cellContent, row) => (
        <>{row.operation === 'E' ? '0' : row.qtd_total_animals_sem_brinco}</>
      )
    },
    {
      dataField: 'final_weight_arroba',
      text: `${props.t('Total weight in @ - End with earring')}`, // 'Peso total em @ - Final com brinco',
      //   sort: true
      formatter: (cellContent, row) => (
        <>{row.operation === 'E' ? '0,00' : formatPrice(row.final_weight_arroba)}</>
      )
    },
    {
      dataField: 'start_weight_arroba',
      text: `${props.t('Total weight in @ - Initial with earring')}`, // 'Peso total em @ - Inicial com brinco',
      //   sort: true
      formatter: (cellContent, row) => (
        <>{row.operation === 'E' ? '0,00' : formatPrice(row.start_weight_arroba)}</>
      )
    },
    {
      dataField: 'gains_arroba',
      text: `${props.t('Total weight gain final @ minus initial @ with earring')}`, // 'Ganho total peso final @ menos inicial @ com brinco',
      //   sort: true
      formatter: (cellContent, row) => (
        <>{row.operation === 'E' ? '0,00' : formatPrice(row.gains_arroba)}</>
      )
    },
    {
      dataField: 'gains_day_arroba',
      text: `${props.t('Average earnings per livestock in @ per day')}`, // 'Ganho médio por gado em @ por dia',
      //   sort: true
      formatter: (cellContent, row) => (
        <>{row.operation === 'E' ? '0,00' : formatPrice(row.gains_day_arroba)}</>
      )
    },
    {
      dataField: 'gains_month_arroba',
      text: `${props.t('Average earnings per livestock in @ per month')}`, // 'Ganho médio por gado em @ por mês',
      //   sort: true
      formatter: (cellContent, row) => (
        <>{row.operation === 'E' ? '0,00' : formatPrice(row.gains_month_arroba)}</>
      )
    },
    {
      dataField: 'gains_year_arroba',
      text: `${props.t('Average earnings per livestock in @ per year')}`, // 'Ganho médio por gado em @ por ano',
      //   sort: true
      formatter: (cellContent, row) => (
        <>{row.operation === 'E' ? '0,00' : formatPrice(row.gains_year_arroba)}</>
      )
    }
  ];

  var node = useRef();
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const showingMessage = (title, type) => {
    setMessageAlert(title);
    setTypeMessage(type);

    dispatch(showMessage());
    setTimeout(() => {
      dispatch(hideMessage());
    }, 2000);
  };

  const { SearchBar } = Search;

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setSyntheticGainList(
      itens.filter((syntheticGain) =>
        Object.keys(syntheticGain).some((key) =>
          syntheticGain[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    );
  };

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'desc'
    }
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>SyntheticGain | Farm SystemW</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title={props.t('Report')} breadcrumbItem={props.t('SyntheticGains')} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={SyntheticGainColumns}
                    data={itens}>
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={itens || []}
                        columns={SyntheticGainColumns}
                        bootstrap4
                        search>
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <DroopSynthetic />
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-rep-plugin">
                                  <div
                                    className="table-responsive mb-0"
                                    data-pattern="priority-columns">
                                    <Table id="itens-1">
                                      <BootstrapTable
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={'table table-striped table-bordered'}
                                        keyField="id"
                                        {...toolkitProps.baseProps}
                                        onTableChange={handleTableChange}
                                        {...paginationTableProps}
                                        ref={node}
                                      />
                                    </Table>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone {...paginationProps} />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

SyntheticGains.propTypes = {
  syntheticGains: PropTypes.array,
  onGetSyntheticGains: PropTypes.func
};

export default withTranslation()(SyntheticGains);

import { call, put, takeEvery } from "redux-saga/effects";

// Ecommerce Redux States
import {
  GET_KPICARDS,
  ADD_NEW_KPICARD,
  UPDATE_KPICARD,
  DELETE_KPICARD,
} from "./actionTypes";
import {
  getKpiCardsFail,
  getKpiCardsSuccess,
  addKpiCardFail,
  addKpiCardSuccess,
  updateKpiCardSuccess,
  updateKpiCardFail,
  deleteKpiCardSuccess,
  deleteKpiCardFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getKpiCards,
  addNewKpiCard,
  updateKpiCard,
  deleteKpiCard,
} from "helpers/backend_fazenda_helper";

function* onGetKpiCards() {
  try {
    const response = yield call(getKpiCards);
    yield put(getKpiCardsSuccess(response.data));
  } catch (error) {
    yield put(getKpiCardsFail(error));
  }
}

function* onUpdateKpiCard({ payload: kpiCard }) {
  try {
    const response = yield call(updateKpiCard, kpiCard);
    yield put(updateKpiCardSuccess(response.data));
  } catch (error) {
    yield put(updateKpiCardFail(error));
  }
}

function* onDeleteKpiCard({ payload: kpiCard }) {
  try {
    const response = yield call(deleteKpiCard, kpiCard);
    yield put(deleteKpiCardSuccess(response.data));
  } catch (error) {
    yield put(deleteKpiCardFail(error));
  }
}

function* onAddNewKpiCard({ payload: kpiCard }) {
  try {
    const response = yield call(addNewKpiCard, kpiCard);
    yield put(addKpiCardSuccess(response.data));
  } catch (error) {
    yield put(addKpiCardFail(error));
  }
}

function* kpiCardSaga() {
  yield takeEvery(GET_KPICARDS, onGetKpiCards);
  yield takeEvery(ADD_NEW_KPICARD, onAddNewKpiCard);
  yield takeEvery(UPDATE_KPICARD, onUpdateKpiCard);
  yield takeEvery(DELETE_KPICARD, onDeleteKpiCard);
}

export default kpiCardSaga;

import {

  GET_PLAN_PROFILES,
  GET_PLAN_PROFILES_SUCCESS,
  GET_PLAN_PROFILES_FAIL,

  GET_PLAN_PROFILES_AVAILABLES,
  GET_PLAN_PROFILES_AVAILABLES_SUCCESS,
  GET_PLAN_PROFILES_AVAILABLES_FAIL,

  ADD_PLAN_PROFILE,
  ADD_PLAN_PROFILE_SUCCESS,
  ADD_PLAN_PROFILE_FAIL,

  DELETE_PLAN_PROFILE,
  DELETE_PLAN_PROFILE_SUCCESS,
  DELETE_PLAN_PROFILE_FAIL
} from "./actionTypes"


export const getPlanProfiles = (planId) => ({
  type: GET_PLAN_PROFILES,
  planId: planId,
})

export const getPlanProfilesSuccess = planProfile => ({
  type: GET_PLAN_PROFILES_SUCCESS,
  payload: planProfile,
})

export const getPlanProfilesFail = error => ({
  type: GET_PLAN_PROFILES_FAIL,
  payload: error,
})

export const getPlanProfilesAvailable = (planId) => ({
  type: GET_PLAN_PROFILES_AVAILABLES,
  planId: planId,
})

export const getPlanProfilesAvailablesSuccess = planProfileAvailable => ({
  type: GET_PLAN_PROFILES_AVAILABLES_SUCCESS,
  payload: planProfileAvailable,
})

export const getPlanProfilesAvailablesFail = error => ({
  type: GET_PLAN_PROFILES_AVAILABLES_FAIL,
  payload: error,
})

export const addNewPlanProfile = (planId, planProfile) => ({
  type: ADD_PLAN_PROFILE,
  planId: planId,
  payload: planProfile,
})

export const addNewPlanProfileSuccess = planProfile => ({
  type: ADD_PLAN_PROFILE_SUCCESS,
  payload: planProfile,
})

export const addNewPlanProfileFail = error => ({
  type: ADD_PLAN_PROFILE_FAIL,
  payload: error,
})

export const deletePlanProfile = (planId, planProfile) => ({
  type: DELETE_PLAN_PROFILE,
  planId: planId,
  payload: planProfile,
})

export const deletePlanProfileSuccess = planProfile => ({
  type: DELETE_PLAN_PROFILE_SUCCESS,
  payload: planProfile,
})

export const deletePlanProfileFail = error => ({
  type: DELETE_PLAN_PROFILE_FAIL,
  payload: error,
})


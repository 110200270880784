import { call, put, takeEvery } from "redux-saga/effects";

// Ecommerce Redux States
import {
  GET_ROLES,
  ADD_NEW_ROLE,
  UPDATE_ROLE,
  DELETE_ROLE,
} from "./actionTypes";
import {
  getRolesFail,
  getRolesSuccess,
  addRoleFail,
  addRoleSuccess,
  updateRoleSuccess,
  updateRoleFail,
  deleteRoleSuccess,
  deleteRoleFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getRoles,
  addNewRole,
  updateRole,
  deleteRole,
} from "helpers/backend_fazenda_helper";

function* onGetRoles() {
  try {
    const response = yield call(getRoles);
    yield put(getRolesSuccess(response.data));
  } catch (error) {
    yield put(getRolesFail(error));
  }
}

function* onUpdateRole({ payload: role }) {
  try {
    const response = yield call(updateRole, role);
    yield put(updateRoleSuccess(response.data));
  } catch (error) {
    yield put(updateRoleFail(error));
  }
}

function* onDeleteRole({ payload: role }) {
  try {
    const response = yield call(deleteRole, role);
    yield put(deleteRoleSuccess(response.data));
  } catch (error) {
    yield put(deleteRoleFail(error));
  }
}

function* onAddNewRole({ payload: role }) {
  try {
    const response = yield call(addNewRole, role);
    yield put(addRoleSuccess(response.data));
  } catch (error) {
    yield put(addRoleFail(error));
  }
}

function* roleSaga() {
  yield takeEvery(GET_ROLES, onGetRoles);
  yield takeEvery(ADD_NEW_ROLE, onAddNewRole);
  yield takeEvery(UPDATE_ROLE, onUpdateRole);
  yield takeEvery(DELETE_ROLE, onDeleteRole);
}

export default roleSaga;

import { useMemo } from 'react';

export default function Can({ permissions, children }) {
  const userHasPermission = useMemo(() => {
    const user = JSON.parse(localStorage.getItem('authUser'));
    const userPermissions = user.permissions;
    const userIsAdmin = user.is_admin;

    if (userIsAdmin) {
      return true;
    }

    if (!permissions) {
      return false;
    }

    if (!user) {
      return (window.location.href = '/login');
    }

    return permissions.filter((permission) => userPermissions.includes(permission)).length > 0;
  }, [permissions]);

  return (userHasPermission && children) || null;
}

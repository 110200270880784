import {
    GET_STOCKS,
    GET_STOCKS_SUCCESS,
    GET_STOCKS_FAIL
} from "./actionTypes";

export const getStocks = () => ({
    type: GET_STOCKS
});

export const getStocksSuccess = stocks => ({
    type: GET_STOCKS_SUCCESS,
    payload: stocks,
});

export const getStocksFail = error => ({
    type: GET_STOCKS_FAIL,
    payload: error,
})

import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import * as moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import DeleteModal from "../../components/Common/DeleteModal";
import {
  getTenants as onGetTenants,
  addNewTenant as onAddNewTenant,
  updateTenant as onUpdateTenant,
  deleteTenant as onDeleteTenant,
} from "store/tenants/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

const Tenants = props => {
  const dispatch = useDispatch();

  const { tenants } = useSelector(state => ({
    tenants: state.Tenant.tenants,
  }));

  const [modal, setModal] = useState(false);
  const [tenantList, setTenantList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [tenant, setTenant] = useState(null);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: (tenant && tenant.name) || ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Name")
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateTenant = {
          id: tenant ? tenant.id : 0,
          name: values.name
        };
        // update tenant
        dispatch(onUpdateTenant(updateTenant));
        validation.resetForm();
      } else {
        const newTenant = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          name: values["name"]
        };
        // save new tenant
        dispatch(onAddNewTenant(newTenant));
        validation.resetForm();
      }
      toggle();
    },
  });

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: tenants.length, // replace later with size(orders),
    custom: true,
  };

  const TenantColumns = [
    {
      text: "id",
      dataField: "id",
      sort: true,
      hidden: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => <>{row.id}</>,
    },
    {
      dataField: "name",
      text: "name",
      sort: true,
    },
    {
      dataField: "menu",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, tenant) => (
        <UncontrolledDropdown direction="left">
          <DropdownToggle href="#" className="card-drop" tag="i">
            <i className="mdi mdi-dots-horizontal font-size-18" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem
              href="#"
              onClick={() => handleTenantClick(tenant)}
            >
              <i className="fas fa-pencil-alt text-success me-1" />
              Edit
            </DropdownItem>
            <DropdownItem
              href="#"
              onClick={() => onClickDelete(tenant)}
            >
              <i className="fas fa-trash-alt text-danger me-1" />
              Delete
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  const toggle = () => {
    if (modal) {
      setModal(false);
      setTenant(null);
    } else {
      setModal(true);
    }
  };


  const handleTenantClick = arg => {
    const tenant = arg;

    setTenant({
      id: tenant.id,
      name: tenant.name,

    });

    setIsEdit(true);
    toggle();
  };

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete tenant
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (tenant) => {
    setTenant(tenant);
    setDeleteModal(true);
  };

  const handleDeleteTenant = () => {
    if (tenant.id) {
      dispatch(onDeleteTenant(tenant));
      onPaginationPageChange(1);
      setDeleteModal(false);
    }
  };

  const { SearchBar } = Search;

  useEffect(async () => {
    await dispatch(onGetTenants());
  }, [dispatch]);





  useEffect(() => {
    if (!isEmpty(tenants)) {
      setTenantList(tenants);
    }
  }, [tenants]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setTenantList(
      tenants.filter(tenant =>
        Object.keys(tenant).some(key =>
          tenant[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    );
  };

  const handleTenantClicks = () => {
    setTenantList("");
    setIsEdit(false);
    toggle();
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  /** set Date formate */
  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteTenant}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Tenant | SistemasW Fazenda</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Ecommerce" breadcrumbItem="Tenants" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={TenantColumns}
                    data={tenants}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={tenants || []}
                        columns={TenantColumns}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={handleTenantClicks}
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    {props.t("New Tenant")}
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    keyField="id"
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    ref={node}
                                  />
                                </div>
                                <Modal isOpen={modal} toggle={toggle}>
                                  <ModalHeader toggle={toggle} tag="h4">
                                    {!!isEdit
                                      ? "Edit Tenant"
                                      : "Add Tenant"}
                                  </ModalHeader>
                                  <ModalBody>
                                    <Form
                                      onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                      }}
                                    >
                                      <Row form>
                                        <Col className="col-12">
                                          <div className="mb-3">
                                            <Label className="form-label">{props.t("Name Tenant")}</Label>
                                            <Input
                                              name="name"
                                              type="text"
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={validation.values.name || ""}
                                              invalid={
                                                validation.touched.name && validation.errors.name ? true : false
                                              }
                                            />
                                            {validation.touched.name && validation.errors.name ? (
                                              <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                            ) : null}
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="text-end">
                                            <button
                                              type="submit"
                                              className="btn btn-success save-tenant"
                                            >
                                              {props.t("Save")}
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Form>
                                  </ModalBody>
                                </Modal>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Tenants.propTypes = {
  tenants: PropTypes.array,
  onGetTenants: PropTypes.func,
  onAddNewTenant: PropTypes.func,
  onDeleteTenant: PropTypes.func,
  onUpdateTenant: PropTypes.func,
};

export default Tenants;

import { call, put, takeEvery } from "redux-saga/effects";

// Ecommerce Redux States
import {
  GET_ROLE_PERMISSIONS,
  GET_ROLE_PERMISSIONS_AVAILABLES,
  ADD_ROLE_PERMISSION,
  DELETE_ROLE_PERMISSION,
} from "./actionTypes";

import {
  getRolePermissionsSuccess,
  getRolePermissionsFail,

  getRolePermissionsAvailablesSuccess,
  getRolePermissionsAvailablesFail,

  addNewRolePermissionSuccess,
  addNewRolePermissionFail,

  deleteRolePermissionSuccess,
  deleteRolePermissionFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getRolePermissions,
  getRolePermissionsAvailable,
  addNewRolePermission,
  deleteRolePermission,
} from "helpers/backend_fazenda_helper";

function* onGetRolePermissions({roleId}) {
  try {
    const response = yield call(getRolePermissions, roleId);
    yield put(getRolePermissionsSuccess(response.data));
  } catch (error) {
    yield put(getRolePermissionsFail(error));
  }
}

function* onGetRolePermissionsAvailable({roleId}) {
  try {
    const response = yield call(getRolePermissionsAvailable, roleId);
    yield put(getRolePermissionsAvailablesSuccess(response.data));
  } catch (error) {
    yield put(getRolePermissionsAvailablesFail(error));
  }
}

function* onDeleteRolePermission({roleId, permissionId, payload}) {
  try {
    const response = yield call(deleteRolePermission, roleId, permissionId, payload);
    yield put(deleteRolePermissionSuccess(response.data));
  } catch (error) {
    yield put(deleteRolePermissionFail(error));
  }
}

function* onAddNewRolePermission({roleId, payload}) {
  try {
    const response = yield call(addNewRolePermission, roleId, payload);
    yield put(addNewRolePermissionSuccess(response.data));
  } catch (error) {
    yield put(addNewRolePermissionFail(error));
  }
}

function* RolePermissionsSaga() {
  yield takeEvery(GET_ROLE_PERMISSIONS, onGetRolePermissions);
  yield takeEvery(GET_ROLE_PERMISSIONS_AVAILABLES, onGetRolePermissionsAvailable);
  yield takeEvery(ADD_ROLE_PERMISSION, onAddNewRolePermission);
  yield takeEvery(DELETE_ROLE_PERMISSION, onDeleteRolePermission);
}

export default RolePermissionsSaga;

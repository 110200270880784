import React from 'react';
import { Spinner } from 'reactstrap';

const Loading = ({ isLoading = false }) => {
  return (
    <React.Fragment>
      <div className="page-content overlay d-flex justify-content-center align-items-center">
        <Spinner
          className="position-fixed top-50 translate-middle"
          color="primary"
          type="grow"
          style={{ display: isLoading ? 'block' : 'none' }}
        />
      </div>
    </React.Fragment>
  );
};

export default Loading;

import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import Lottie from 'lottie-react';
import animmated3 from '../../../../animation/Animation3.json';
import './HeroSection.css'; // Importando arquivo CSS para estilos adicionais

const Section = () => {
  return (
    <React.Fragment>
      <section className="section hero-section bg-ico-hero py-5" id="home">
        <div className="bg-overlay bg-primary" />
        <Container>
          <Row className="align-items-center">
            <Col lg="6" md="12" className="text-lg-start text-center">
              <h1 className="text-white font-weight-semibold mb-3 hero-title">
                Simplicidade e Robustez para a Gestão dos Animais
              </h1>
              <p className="font-size-14 text-white-50">
                Resultados online, relatórios de ganho de peso, controle da quantidade de animais,
                multifazenda. Com os dados você consegue melhorar o ganho anual de arrobas do seu
                lote de gado.
              </p>
              <button className="btn-custom-primary mt-3">Saiba Mais</button>
              <button className="btn-custom-secondary mt-3 ms-3">Contato</button>
            </Col>
            <Col lg="6" md="12" className="text-center mt-5 mt-lg-0">
              <Lottie animationData={animmated3} style={{ width: '100%', height: '100%' }} />
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Section;
import React, { useEffect } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import MetaTags from 'react-meta-tags';
import { useSelector, useDispatch } from 'react-redux';
import { getPlans as onGetPlans } from "store/plans/actions";
import './PagesPricing.css';
import CardPricing from "./card-pricing";

const PagesPricing = () => {
  const dispatch = useDispatch();

  const { plans } = useSelector(state => ({
    plans: state.Plan.plans
  }));

  useEffect(() => {
    dispatch(onGetPlans());
  }, [dispatch]);


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Farm System WShare | Pricing</title>
        </MetaTags>
        <Container fluid className="text-center">
          <Row className="justify-content-center mb-5">
            <Col lg={9}>
              <div className="text-center mb-5">
                <h4 className="display-4">Escolha seu plano e preço</h4>
                <p className="lead text-muted">
                  Nossos planos e preços se enquandram nas modalidades mais utilizadas.
                  Trabalhamos com animais ativos.
                </p>
                <p className="text-muted">
                  Ex. Se escolheu o plano de 500 a 1.000 animais ativos
                  e atualmente possui <strong>891</strong> bois em regime de engorda.
                  Isso significa que estes animais estão nas fazendas em engorda.
                </p>
                <p className="text-muted">
                  Após a venda de 80 animais,
                  o sistema irá contabilizar <strong>811</strong> animais ativos, contudo o sistema manterá
                  o histórico dos animais vendidos.
                </p>
              </div>
            </Col>
          </Row>

          <Row className="pricing-row">
            {plans && plans.length > 0 && plans.map((plan, key) => (
              <Col className="pricing-col" key={"_pricing_" + key}>
                <CardPricing pricing={plan} link="checkout" />
              </Col>
            ))}
          </Row>

          <Row className="justify-content-center mt-5">
            <Col lg={8}>
              <h5 className="mb-4">O que você ganha com nossos planos:</h5>
              <ul className="list-unstyled benefits-list text-left">
                <li>✔ Monitoramento contínuo dos animais</li>
                <li>✔ Relatórios detalhados de ganho de peso</li>
                <li>✔ Suporte técnico especializado</li>
                <li>✔ Acesso a ferramentas de análise avançada</li>
                <li>✔ Garantia de satisfação</li>
              </ul>
            </Col>
          </Row>

          <Row className="justify-content-center mt-5">
            <Col lg={8}>
              <h5 className="mb-4">Perguntas Frequentes</h5>
              <div className="faq-item mb-3">
                <h6>Como faço para escolher o plano certo para mim?</h6>
                <p className="text-muted">Nossa equipe está disponível para ajudar você a escolher o plano que melhor se adequa às suas necessidades.</p>
              </div>
              <div className="faq-item mb-3">
                <h6>Posso mudar de plano no futuro?</h6>
                <p className="text-muted">Sim, você pode mudar de plano a qualquer momento entrando em contato com nosso suporte.</p>
              </div>
              <div className="faq-item mb-3">
                <h6>Qual é a política de reembolso?</h6>
                <p className="text-muted">Oferecemos uma garantia de satisfação com reembolso total dentro dos primeiros 30 dias.</p>
              </div>
            </Col>
          </Row>
          <Row className="mt-5 text-center">
            <Col>
              <button className="btn-custom-primary mt-3">Saiba Mais</button>
              <button className="btn-custom-secondary mt-3 ms-3">Contato</button>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PagesPricing;
import {
  GET_ANALYTIC_GAINS_SUCCESS,
  GET_ANALYTIC_GAINS_FAIL,
  GET_SYNTHETIC_GAINS_SUCCESS,
  GET_SYNTHETIC_GAINS_FAIL,
  GET_INVENTORY_SUCCESS,
  GET_INVENTORY_FAIL
} from './actionTypes';

const INIT_STATE = {
  analyticGains: [],
  syntheticGains: [],
  inventory: [],
  error: {}
};

const ReportGains = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ANALYTIC_GAINS_SUCCESS:
      return {
        ...state,
        analyticGains: action.payload
      };

    case GET_ANALYTIC_GAINS_FAIL:
      return {
        ...state,
        error: action.payload
      };
    case GET_SYNTHETIC_GAINS_SUCCESS:
      return {
        ...state,
        syntheticGains: action.payload
      };

    case GET_SYNTHETIC_GAINS_FAIL:
      return {
        ...state,
        error: action.payload
      };
    case GET_INVENTORY_SUCCESS:
      return {
        ...state,
        inventory: action.payload
      };

    case GET_INVENTORY_FAIL:
      return {
        ...state,
        error: action.payload
      };

    default:
      return state;
  }
};

export default ReportGains;

import React, { useState } from 'react';
import { Row, Button, FormGroup, Card, CardTitle, Input, Col } from 'reactstrap';
import { Formik, Field, Form } from 'formik';

import 'react-datepicker/dist/react-datepicker.css';
import { withTranslation } from 'react-i18next';

const SearchSynthetic = (props) => {

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  }

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Data de Inicio', startDate)
    console.log('Data de fim', endDate)
  }


  return (
    <Formik
      initialValues={{
        collect_id: '',
        collect_idQuantity: 20,

        measurement_condition: '',
        measurement_conditionQuantity: 30,

        difference_days: '>',
        difference_daysQuantity: 40,

        avg_month: '>',
        avg_monthQuantity: 50,

        gains_kg: '',
        gains_kgQuantity: 60,

        final_weight: '',
        final_weightQuantity: 70,

        qtd_total_animals: '',
        qtd_total_animalsQuantity: 80,

        qtd_total_animals_com_brinco: '',
        qtd_total_animals_com_brincoQuantity: 90,

        qtd_total_animals_sem_brinco: '',
        qtd_total_animals_sem_brincoQuantity: 100,

        start_end_weight_arroba: '',
        start_end_weight_arrobaQuantity: 105,

        start_weight_arroba: '',
        start_weight_arrobaQuantity: 110,

        start_weight: '',
        start_weightQuantity: 120,

        final_weight_arroba: '',
        final_weight_arrobaQuantity: 130,

        gains_day_arroba: '',
        gains_day_arrobaQuantity: 140,

        gains_month_arroba: '',
        gains_month_arrobaQuantity: 150,

        gains_year_arroba: '',
        gains_year_arrobaQuantity: 160,

        initialDate: '',
        initialDateQuantity: 170,

        initialDateEnd: '',
        initialDateEndQuantity: 180,

        de: new Date(),
        ate: new Date(),

      }}
      onSubmit={(values, { setSubmitting }) => {
        // Lógica de envio do formulário aqui...
        console.log(values);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form inline>
          <Row form>
          <Col md={4}>
              <Card style={{ margin: 0 }}>
                <CardTitle>Coleta 1</CardTitle>
                <FormGroup>
                <Field as="select" name="difference_days" id="difference_days" className="form-control">
                        {['test1', 'test2', 'test3', 'test4'].map(op => <option key={op} value={op}>{op}</option>)}
                      </Field>
                </FormGroup>
              </Card>
            </Col>
            <Col md={4}>
              <Card style={{ margin: 0 }}>
                <CardTitle>Coleta 2</CardTitle>
                <FormGroup>
                <Field as="select" name="difference_days" id="difference_days" className="form-control">
                        {['test20', 'test60', 'test90', 'test80'].map(op => <option key={op} value={op}>{op}</option>)}
                      </Field>
                </FormGroup>
              </Card>
            </Col>
          </Row>
          <Row form>
            <Col md={2}>
              <Card style={{ margin: 0 }}>
                <CardTitle>Id</CardTitle>
                <FormGroup>
                  <Field type="id" name="collect_id" id="collect_id" placeholder="Id" className="form-control" />
                </FormGroup>
              </Card>
            </Col>
            <Col >
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t("Total measurement days")}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col>
                      <Field as="select" name="difference_days" id="difference_days" className="form-control">
                        {['>', '<', '>=', '<='].map(op => <option key={op} value={op}>{op}</option>)}
                      </Field>
                    </Col>
                    <Col>
                      <Field type="number" name="difference_daysQuantity" id="difference_daysQuantity" placeholder="Quantity" className="form-control" />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>
            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t("Average measurement months")}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field as="select" name="avg_month" id="avg_month" className="form-control">
                        {['>', '<', '>=', '<='].map(op => <option key={op} value={op}>{op}</option>)}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field type="number" name="avg_monthQuantity" id="avg_monthQuantity" placeholder="Quantity" className="form-control" />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t("Measurement Condition")}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field as="select" name="measurement_condition" id="measurement_condition" className="form-control">
                        {['>', '<', '>=', '<='].map(op => <option key={op} value={op}>{op}</option>)}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field type="number" name="measurement_conditionQuantity" id="measurement_conditionQuantity" placeholder="Quantity" className="form-control" />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t("Average measurement Condition")}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field as="select" name="avg_month" id="avg_month" className="form-control">
                        {['>', '<', '>=', '<='].map(op => <option key={op} value={op}>{op}</option>)}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field type="number" name="avg_monthQuantity" id="avg_monthQuantity" placeholder="Quantity" className="form-control" />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t("Difference final total weight minus initial total weight")}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field as="select" name="gains_kg" id="gains_kg" className="form-control">
                        {['>', '<', '>=', '<='].map(op => <option key={op} value={op}>{op}</option>)}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field type="number" name="gains_kgQuantity" id="gains_kgQuantity" placeholder="Quantity" className="form-control" />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t("Total number of cattle")}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field as="select" name="qtd_total_animals" id="qtd_total_animals" className="form-control">
                        {['>', '<', '>=', '<='].map(op => <option key={op} value={op}>{op}</option>)}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field type="number" name="qtd_total_animalsQuantity" id="qtd_total_animalsQuantity" placeholder="Quantity" className="form-control" />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t("Number of cattle with earring")}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field as="select" name="qtd_total_animals_com_brinco" id="qtd_total_animals_com_brinco" className="form-control">
                        {['>', '<', '>=', '<='].map(op => <option key={op} value={op}>{op}</option>)}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field type="number" name="qtd_total_animals_com_brincoQuantity" id="qtd_total_animals_com_brincoQuantity" placeholder="Quantity" className="form-control" />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t("Number of cattle without earring")}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field as="select" name="qtd_total_animals_sem_brinco" id="qtd_total_animals_sem_brinco" className="form-control">
                        {['>', '<', '>=', '<='].map(op => <option key={op} value={op}>{op}</option>)}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field type="number" name="qtd_total_animals_sem_brincoQuantity" id="qtd_total_animals_sem_brincoQuantity" placeholder="Quantity" className="form-control" />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t("Total Weight End With Earring")}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field as="select" name="start_end_weight_arroba" id="start_end_weight_arroba" className="form-control">
                        {['>', '<', '>=', '<='].map(op => <option key={op} value={op}>{op}</option>)}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field type="number" name="start_end_weight_arrobaQuantity" id="start_end_weight_arrobaQuantity" placeholder="Quantity" className="form-control" />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t("Total Weight Initial WithE arring")}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field as="select" name="start_weight_arroba" id="start_weight_arroba" className="form-control">
                        {['>', '<', '>=', '<='].map(op => <option key={op} value={op}>{op}</option>)}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field type="number" name="start_weight_arrobaQuantity" id="start_weight_arrobaQuantity" placeholder="Quantity" className="form-control" />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t("Total Weight GainFinal Minus Initial With Earring")}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field as="select" name="final_weight_arroba" id="final_weight_arroba" className="form-control">
                        {['>', '<', '>=', '<='].map(op => <option key={op} value={op}>{op}</option>)}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field type="number" name="final_weight_arrobaQuantity" id="final_weight_arrobaQuantity" placeholder="Quantity" className="form-control" />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t("Average Earnings Per Livestock InPer Day")}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field as="select" name="gains_day_arroba" id="gains_day_arroba" className="form-control">
                        {['>', '<', '>=', '<='].map(op => <option key={op} value={op}>{op}</option>)}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field type="number" name="gains_day_arrobaQuantity" id="gains_day_arrobaQuantity" placeholder="Quantity" className="form-control" />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={6}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t("Average Earnings Per Livestock InPer Year")}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={6}>
                      <Field as="select" name="gains_year_arroba" id="gains_year_arroba" className="form-control">
                        {['>', '<', '>=', '<='].map(op => <option key={op} value={op}>{op}</option>)}
                      </Field>
                    </Col>
                    <Col md={6}>
                      <Field type="number" name="gains_year_arrobaQuantity" id="gains_year_arrobaQuantity" placeholder="Quantity" className="form-control" />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>

            <Col md={3}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t("Initial date")}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={12}>
                      <Input type='date' id='de' name='de' placeholder='De' />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>
            <Col md={3}>
              <Card style={{ margin: 0 }}>
                <CardTitle>{props.t("Final date")}</CardTitle>
                <FormGroup>
                  <Row>
                    <Col md={12}>
                      <Input type='date' id='ate' name='ate' placeholder='Ate' />
                    </Col>
                  </Row>
                </FormGroup>
              </Card>
            </Col>
            {/* Replicar este bloco para cada campo */}
          </Row>
          <Row>
            <div style={{ display: "flex", justifyContent: "center", width: "100%", gap: "10px", marginTop: "3rem", left: "40%" }}>
              <Button color="primary" type="submit">
                {props.t("Search filter")}
              </Button>
              <Button color="danger" type="reset">
                {props.t("Clear")}
              </Button>
            </div>
          </Row>
        </Form>
      )}
    </Formik>
  );
}


export default withTranslation()(SearchSynthetic);
